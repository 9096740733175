<template>
<div>
    <!-- <button type="button" :class="{'boton':!boton_menu,'botonMenu':boton_menu}" :style="{backgroundImage: 'url('+image+')',marginTop: margin_top+'px'}" 
            @click="$emit('click')">{{label}}</button>    -->

    <button type="button" class="boton" :style="estilos" @click="$emit('click')">{{label}}</button>   


    <!-- HACIENDOLO CON v-icon: El problema es que no queda centrado 
        <button type="button" :class="{'boton':!boton_menu,'botonMenu':boton_menu}" :style="{marginTop: margin_top+'px'}" 
            @click="$emit('click')"><v-icon class="mr-4" color="blue">save</v-icon><br/>{{label}}</button>    -->

</div>
</template>

<script>

 
export default {
    name: 'ControlBoton',   
    data() {
        return {
        }
    },
    computed: {
        estilos(){
            let resul=[];
            //resul["backgroundImage"]="url("+this.image+")";
            if (this.image) resul.push({"backgroundImage":"url("+this.image+")"});
            if (this.tiene_borde) {
                resul.push({border: "1px solid #878787"});
                resul.push({borderRadius: "5px"});
            } 
            else {
                resul.push({border: "0px"});
            }
            if (this.label) resul.push({backgroundPosition: "center top"});                            
            else resul.push({backgroundPosition: "center center"});            
            if (this.width!==0) resul.push({"width":this.width+'px'});
            resul.push({"color":"#"+this.color_texto});
            resul.push({"marginTop":this.margin_top+'px'});
            //  resul.push({color:"#ff0000"});
            return resul;
        }

    },
    props: {
        label: String,
        image: String,        
        margin_top: {type: Number, required: false, default: 0 },
        width: {type: Number, required: false, default: 0 },
        tiene_borde: {type: Boolean, required: false, default: true },
        color_texto: {type: String, required: false, default: '000000' },
    },
    watch: {
    },    
    methods: {
    },
  

}

</script>

<style>

 .boton {
     padding-top: 24px;
     padding-left: 5px;
     padding-right: 5px;
     font-size: 12px ;
     height: 40px;
     float:left ;
 }
</style>


