<template>
<div>


    <v-row>
        <v-btn class="mt-4 ml-6" :color="color_texto"  @click="visible=true;"  
           width="5" x-small></v-btn> 
    </v-row>
    <v-dialog max-width="300" v-model="visible">
        <v-card>
            <v-color-picker v-model="controlColorValue" @input="aceptar" :swatches="colores"  dot-size="25" hide-inputs  hide-canvas   hide-sliders   show-swatches   swatches-max-height="200" ></v-color-picker>

            <!-- <div class="botonizquierda"><v-btn text color="primary" @click="aceptar">{{$i18n.t('aceptar')}} </v-btn></div>
            <div class="botonderecha"><v-btn text color="error" @click="cerrar">{{$i18n.t('cancelar')}}</v-btn></div> -->
        </v-card>
   </v-dialog>
</div>
</template>

<script>

import {mapMutations} from 'vuex';
 
export default {
    name: 'ControlColor',   
    data() {
        return {
            cargando:false,
            visible: false,
            color_texto: '#000000',
            controlColorValue: '#000000',
            colores: [
                ['#FF8080', '#FF0000', '#804040'],
                ['#FFFF00', '#FF8000', '#808040'],
                ['#008000', '#80FF00', '#808080'],
                ['#80FFFF', '#0000FF', '#000080'],
                ['#8000FF', '#FF00FF', '#000000'],
            ],
           
        }
    },
    props: {
        label: String,
        value: String,
        // disabled: Boolean,
        // clearable: {type: Boolean, required: false, default: true },
        // required: {type: Boolean, required: false, default: false }
    },
    watch: {
    //    controlHoraValue (val) {  
    //         console.log('wath controlHoraValue:'+val) 
    //    },
      color_texto (val) {  
        this.$emit('input',val)
      },
      controlColorValue(val) {
        //this.controlColorValue = newval;
        this.color_texto = val;

      },
      value: { immediate: true, handler(newval, oldval) {
          //console.log("value change:"+newval);
                this.controlColorValue = newval;
                this.color_texto = newval;
            // if (!newval) {
            //     this.controlColorValue = null;
            //     this.color_texto = "";
            // }
            // else {
            // //   if (typeof newval =="string") {
            // //       this.controlHoraValue = newval.substring(0,5);
            // //       this.hora_texto  = newval.substring(0,5);
            // //   }
            // }
         }, 
      },        
    },    
    methods: {
      ...mapMutations(['mostrarError']),
        aceptar() {
            if (this.controlColorValue===this.color_texto) return;
            if (this.controlColorValue) this.color_texto = this.controlColorValue.hex;
            else this.color_texto = "";
            this.visible=false;
        },
        abrir() {
            this.cargando = true;
            this.visible = true;
            this.cargando = false;
        },
        cerrar(){
            this.visible = false;
        },
       
       
    },  

}

</script>

<style>
  .botonizquierda {
      position: absolute;
      left:10px;
      bottom: 10px;
  }
  .botonderecha {
      position: absolute;
      bottom: 10px;
      right:20px;
  }
</style>
