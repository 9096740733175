<template>
  <v-row justify="center">
    <Confirm ref="confirmaEliminar" :label="$t('estaseguroeliminarcita')"  :label_aceptar="$t('eliminar')" icon_aceptar="delete" color_aceptar="error" @confirm="eliminar2" />
    <v-dialog persistent max-width="600" v-model="visible">
      <v-card height="350">
        <v-card-title class="cabeceraModal">
            <div class="cabecera">{{instrucciones}}</div>                         
        </v-card-title>
          
        <v-card-text >

          <div v-if="pantalla===0 && visible">
            <table>
              <tr><th>{{$t('nombre')}}:</th><td>{{pantalla0_nombre}}</td></tr>
              <tr><th>{{$t('apellidos')}}:</th><td>{{pantalla0_apellidos}}</td></tr>
              <tr><th>{{$t('telefono')}}:</th><td>{{pantalla0_telefono}}</td></tr>
              <tr><th>{{$t('email')}}:</th><td>{{pantalla0_email}}</td></tr>
              <tr><th>{{$t('motivo')}}:</th><td>{{cita.descripcion}}</td></tr>
            </table>
          </div>

          <div v-if="pantalla===1">
            <v-row>
              <v-col cols="3" class="etiqueta">{{$t('nombre')}}<br/><ControlTexto type="texto" v-model="filtro_nombre" width="135" font_size="12" height="25" color="darkblue"/></v-col>
              <v-col cols="3" class="etiqueta">{{$t('apellidos')}}<br/><ControlTexto type="texto" v-model="filtro_apellidos" width="135" font_size="12" height="25" color="darkblue"/></v-col>
              <v-col cols="3" class="etiqueta">{{$t('telefono')}}<br/><ControlTexto type="texto" v-model="filtro_telefono" width="135" font_size="12" height="25" color="darkblue"/></v-col>
              <v-col cols="3" class="etiqueta">{{$t('email')}}<br/><ControlTexto type="texto" v-model="filtro_email" width="135" font_size="12" height="25" color="darkblue"/></v-col>
            </v-row>
            <div id='divResultado' class="divResultado">
              <table class="filatabla"><tr><td><u>Nombre y apellidos</u></td><td><u>Teléfono</u></td><td><u>Email</u></td></tr>
              <tr v-for="(item,index) in  personas_filtradas" :key="item.cod" @click="click(item,index)" :style="{'background':color_fondo(index)}" >
                <td>{{mayuscula_palabra(item.nombre)}} {{mayuscula_palabra(item.apellidos)}}</td>
                <td>{{item.telefono}}</td>
                <td>{{item.email}}</td>
              </tr>
              </table>
          </div>
         
          </div>
          <div v-if="pantalla===2">
            <table>
              <tr><th>{{$t('nombre')}}:</th><td>{{rec.nombre}}</td></tr>
              <tr><th>{{$t('apellidos')}}:</th><td>{{rec.apellidos}}</td></tr>
              <tr><th>{{$t('telefono')}}:</th><td><ControlTexto type="textarea" v-model="rec.telefono" width="370" font_size="12" height="90" color="darkblue"/></td></tr>
              <tr><th>{{$t('email')}}:</th><td><ControlTexto type="texto" v-model="rec.email" width="370" font_size="12" height="25" color="darkblue"/></td></tr>
            </table>
          </div>

        </v-card-text>

        <v-card-actions class="pie">
            <v-btn  @click="atras" small><v-icon color="red" class="mr-4">arrow_back_ios</v-icon>{{etiqueta_volver}}</v-btn>
            <v-btn v-if="pantalla===0" @click="siguiente" small><v-icon color="primary" class="mr-4">arrow_forward_ios</v-icon>{{$t('continuar')}}</v-btn>
            <v-btn v-if="pantalla===0" @click="eliminar" small><v-icon color="error" class="mr-4">delete</v-icon>{{$t('eliminarcita')}}</v-btn>
            <v-btn v-if="pantalla===1" @click="editaPersona" small :disabled="indice<0"><v-icon color="primary" class="mr-4">arrow_forward_ios</v-icon>{{$t('estaeslapersona')}}</v-btn>
            <v-btn v-if="pantalla===1" @click="crearnuevo" small ><v-icon color="orange" class="mr-4">person_add</v-icon>{{$t('noexistecrearnueva')}}</v-btn>

            <v-btn v-if="pantalla===2" @click="actualizaPersona" small><v-icon color="primary" class="mr-4">save</v-icon>{{$t('guardardatos')}}</v-btn>
            <v-btn v-if="pantalla===2" @click="noActualizaPersona" small><v-icon color="error" class="mr-4">cancel</v-icon>{{$t('noactualizardatos')}}</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations, mapGetters } from 'vuex';
//mport axios from "axios";
import ControlTexto from '@/components/ControlTexto.vue';
import Confirm from '@/components/Confirm.vue';
import {call_url, getParametro, mayuscula_palabra, quitar_acentos, tienePermiso, Persona, getPersonas} from '@/library/misFunciones.js';

export default {
    name: 'ColEdit',   
    components: { ControlTexto, Confirm},

    computed: {
        ...mapState(['sesion']),
       instrucciones(){
           if (this.pantalla===0) return this.$i18n.t('pantalla0col');
           if (this.pantalla===1) return this.$i18n.t('pantalla1col');
           if (this.pantalla===2) return this.$i18n.t('pantalla2col');
       },
       num_personas(){
            return this.sesion.personas.length;
       }, 
       etiqueta_volver(){
         if (this.pantalla===0) return this.$i18n.t('salir');
         else return this.$i18n.t('volver');
       },
       personas_filtradas(){
         let resul=[]
         for (let i=0; i<this.sesion.personas.length;i++) {
           const nombre = quitar_acentos(this.filtro_nombre.toLowerCase());
           const apellidos = quitar_acentos(this.filtro_apellidos.toLowerCase());
           const telefono = quitar_acentos(this.filtro_telefono.toLowerCase());
           const email = quitar_acentos(this.filtro_email.toLowerCase());
           if (this.filtro_nombre.length>0 && this.sesion.personas[i].nombre.toLowerCase().indexOf(nombre)>=0) resul.push(this.sesion.personas[i]);
           else if (this.filtro_apellidos.length>0 && this.sesion.personas[i].apellidos.toLowerCase().indexOf(apellidos)>=0) resul.push(this.sesion.personas[i]);
           else if (this.filtro_telefono.length>0 && this.sesion.personas[i].telefono.toLowerCase().indexOf(telefono)>=0) resul.push(this.sesion.personas[i]);
           else if (this.filtro_email.length>0 && this.sesion.personas[i].email.toLowerCase().indexOf(email)>=0) resul.push(this.sesion.personas[i]);
         }
         this.indice = -1;
         return resul;
       },
     
    },
    data() {
        return {
            cargando:false,
            visible: false,
            pantalla: 0,
            //rec: new Cita({agenda:null,tipo_cita:'C',persona:null,acto:null,'fecha':'',todoeldia: false,'hora':'','duracion':'',recurso:null,urgencia:null,estado:null,descripcion:'',color:'#000000'}),          
            rec: {cod:'',nombre:'',apellidos:''},
            rules: {
            },
            filtro_nombre: '',
            filtro_apellidos: '',
            filtro_telefono: '',
            filtro_email: '',
            pantalla0_nombre: '',
            pantalla0_apellidos: '',
            pantalla0_telefono: '',
            pantalla0_email: '',
            cita: null,
            indice: -1,

            cabecera: [
                { text: this.$i18n.t('nombre'), value: 'nombre' },
                { text: this.$i18n.t('telefono'), value: 'telefono',class:'d-none d-sm-table-cell'},
                { text: this.$i18n.t('email'), value: 'email',class:'d-none d-md-table-cell'}],
            //personas: [],

        }
    },
    methods: {
        tienePermiso,
        getParametro,
        getPersonas,
        mayuscula_palabra,
        ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError','mostrarMensaje']),

        inicio(){
          this.pantalla===0;
          this.indice=0;
        },
        atras(){
            if (this.pantalla===0) this.visible = false;
            else if (this.pantalla===1) {
              this.indice = -1;
              this.pantalla = 0;
            }
            else this.pantalla = 0;
        },
        siguiente(){
            if (this.cita.m_col.length>=3) { //COL1: pasamos la busqueda de persona
              this.filtro_nombre = this.cita.m_col[0];
              this.filtro_apellidos = this.cita.m_col[1];
              this.filtro_telefono = this.cita.m_col[2];
              this.filtro_email = this.cita.m_col[3];
              this.getPersonas();
              this.pantalla = 1;
            }
            else { //COL2: pasamos a la edicion de persona
              this.editaPersona2();
              this.pantalla = 2;
            }
            
        },
       color_fondo(indice){
         if (this.indice===indice) return "tomato";
         else return "#f5f5f5";
       },

        async editar(cita){
          try{
            this.cita = cita;
            if (this.cita.m_col.length<3) { //COL2: recuperamos los datos de la persona
              this.cargando = true;
              const data = await call_url("/agendab/getPersonaFromCita.php",{"cod":this.cita.cod});
              if (data.resul==="OK") {

                this.rec = new Persona(data.persona);
                
                this.pantalla0_nombre = this.rec.nombre;
                this.pantalla0_apellidos = this.rec.apellidos;
                this.pantalla0_telefono = this.rec.telefono;
                this.pantalla0_email = this.rec.email;
                

              }
              else this.mostrarError(this.$i18n.t(data.mensaje));
            }
            else {
              this.pantalla0_nombre = this.cita.m_col[0];
              this.pantalla0_apellidos = this.cita.m_col[1];
              this.pantalla0_telefono = this.cita.m_col[2];
              this.pantalla0_email = this.cita.m_col[3];
            }
            this.visible = true;
          }
          catch(error) {
            console.error(error);
            this.mostrarError(error);
          }
          finally {
            this.cargando = false;
          }


        },
        editaPersona(){ //COL1
          this.pantalla = 2;
          this.rec = new Persona(this.personas_filtradas[this.indice]);
          if (this.rec.telefono.indexOf(this.cita.m_col[2])<0) {
            if (this.rec.telefono.trim()==="") this.rec.telefono=this.cita.m_col[2];
            else  this.rec.telefono=this.cita.m_col[2]+"\n"+this.rec.telefono;
          } 
          if (this.rec.email.indexOf(this.cita.m_col[3])<0) {
            if (this.rec.email.trim()==="") this.rec.email=this.cita.m_col[3];
            else  this.rec.email=this.cita.m_col[3]+","+this.rec.email;
          }
        },
        async editaPersona2(){ //COL2 
                if (this.rec.telefono.indexOf(this.cita.m_col[0])<0) {
                  if (this.rec.telefono.trim()==="") this.rec.telefono=this.cita.m_col[0];
                  else  this.rec.telefono=this.cita.m_col[0]+"\n"+this.rec.telefono;
                } 
                if (this.rec.email.indexOf(this.cita.m_col[1])<0) {
                  if (this.rec.email.trim()==="") this.rec.email=this.cita.m_col[1];
                  else  this.rec.email=this.cita.m_col[1]+","+this.rec.email;
                }
        },

        cerrar(){
            this.visible = false;
            this.pantalla = 0;
        },  

        // async getPersonas(){
        //   try{
        //     this.mostrarLoading(this.$i18n.t('leyendodatos')+'...')
        //     const objeto = await call_url("/agendab/getPersonas.php");
        //     if (objeto.resul==="OK") {
        //       this.personas = objeto.personas;
        //     }
        //     else this.mostrarError(this.$i18n.t(objeto.mensaje)); 
        //   }
        //   catch(error) {
        //     console.log(error);
        //     this.mostrarError(error.message)
        //   }
        //   finally {
        //     this.ocultarLoading();
        //   }
        // },
        click(e,indice) {
          this.indice = indice;
        },

        async crearnuevo(){
          if (this.graba_persona('',this.filtro_nombre,this.filtro_apellidos,this.filtro_telefono,this.filtro_email,'')) this.mostrarMensaje(this.$i18n.t('personagrabadacorrectamente'));
        },
        async actualizaPersona(){
          if (this.graba_persona(this.rec.cod,this.rec.nombre,this.rec.apellidos,this.rec.telefono,this.rec.email,'')) this.mostrarMensaje(this.$i18n.t('personagrabadacorrectamente'));
        },
        async noActualizaPersona(){
          if (this.graba_persona(this.rec.cod,'','','','','NAP')) this.mostrarMensaje(this.$i18n.t('citagrabadacorrectamente'));          
        },

        async graba_persona(cod,nombre,apellidos,telefono,email,accion){
          try{
            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            const datos={cod:cod,nombre:nombre,apellidos:apellidos,telefono:telefono,email:email,cod_cita:this.cita.cod,descripcion:this.cita.descripcion,accion:accion};
            const data = await call_url("/agendab/grabaPersona.php",datos);
            if (data.resul==="OK") {
              this.$emit('input');
              this.$emit('change')
              this.visible = false;
              this.pantalla = 0;
              this.sesion.ultima_lectura_personas = 0;
              return true 
            }
            else this.mostrarError(data.mensaje);
          }
          catch(error) {
            console.log(error);
            this.mostrarError(error.response.data);
          }
          finally {
            this.ocultarLoading();
          }
          return false;

        },

        eliminar(){
          this.$refs.confirmaEliminar.abrir();
        },

        async eliminar2(){
          try{
            
            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            //const data = (await axios.post(getServidor()+"/agendab/eliminaCita.php",this.cita.cod)).data;
            const data = await call_url("/agendab/eliminaCita.php",this.cita.cod);
            if (data.resul==="OK") {
              this.visible = false;
              this.mostrarMensaje(this.$i18n.t('citaeliminada'))
              this.$emit('change')
            }
            else {
              this.mostrarError(data.mensaje);
            }
            
          }
          catch(ex) {
            console.log(ex);
            //this.mostrarError(error.message);
          }
          finally {
            this.ocultarLoading();
          }

        },
     
     

    },

    watch: {

      cargando() {
          if (this.cargando) this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
          else this.ocultarLoading();
      },
    },

    

}

</script>

<style >
th {
  vertical-align:top;
  text-align: right;
  padding: 5px;
}
.cabecera{
    font-size: 14px;
    color: red;
    line-height: 150%;
    text-align: justify;
    text-justify: auto;
    word-break: normal;
}
.pie {
  position: absolute;
  bottom: 0px;
}
.divResultado{
  width:100%;
  height:150px;
  overflow:auto;
  font-size: 12px;
  margin-top: 10px;
}
.etiqueta {
  font-size: 12px;
  color:darkblue;
}
.filatabla{
  border-collapse:collapse;
  line-height: 30px;
  width:100%;
  padding: 5px;
  background:#f5f5f5;
  
}
</style>