<template>
<div>


    <v-dialog max-width="200" v-model="visible">
        <v-card>
            <table>
                <tr @click="activar_marcador(null)"><td><v-icon class="mr-4">palette</v-icon></td><td>{{$t('desactivarmarcador')}}</td></tr>
                <tr @click="activar_marcador(deleter)"><td><v-icon class="mr-4">format_color_reset</v-icon></td><td>{{$t('eliminadormarcas')}}</td></tr>
                <tr v-for="(item) in sesion.marcadores" :key="item.indice" @click="activar_marcador(item)"><td :style="'background-color:#'+item.color"></td><td>{{item.nombre}} </td></tr>
            </table>
        </v-card>
   </v-dialog>
</div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    name: 'Marcadores',   
    data() {
        return {
            visible: false,
            colores: [
                ['#FF8080', '#FF0000', '#804040'],
                ['#FFFF00', '#FF8000', '#808040'],
                ['#008000', '#80FF00', '#808080'],
                ['#80FFFF', '#0000FF', '#000080'],
                ['#8000FF', '#FF00FF', '#000000'],
            ],
            deleter: {cod:0, nombre:'Eliminador',color:'ff0000'}
           
        }
    },
    props: {
    },
    computed: {
        ...mapState(['sesion']),
    },
    watch: {
    },    
    methods: {
        abrir() {
            this.visible = true;
        },
        cerrar(){
            this.visible = false;
        },
        activar_marcador(valor) {
            this.sesion.marcador = valor;
            this.cerrar();
        }
       
       
    },
  

}

</script>

<style>
tr {
    height:25px;
    cursor:alias;    
}
</style>
