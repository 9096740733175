<template>
  <v-row justify="center">
    <CitaEdit ref="citaEdit" @change="consulta"/>

    <v-dialog  max-width="550" v-model="visible">
      <v-card>
        <v-card-title class="cabeceraModal">
          <v-row>
            <v-col cols="2"><v-icon left large>mdi-calendar-month</v-icon></v-col>
            <v-col>{{$t('citasde')}} {{nombre}} {{apellidos}}</v-col>
          </v-row>
        </v-card-title>
          
        <v-card-text>
            <v-data-table  v-if="num_filas>0" :headers="cabecera" :items="citas" :search="search" :custom-filter="buscaTabla" :hide-default-footer="num_filas<=10" >
                <template v-slot:item="row">
                    <tr>
                    <td>{{fecha_cita(row.item.fecha)}} {{$i18n.t('alas')}} {{hora_cita(row.item.fecha)}} 
                        <div class="d-block d-sm-none"> {{$i18n.t('agenda')}}: {{agenda(row.item.agendas)}}</div>
                    </td>
                    <td >{{duracion(row.item.fecha,row.item.fecha_fin)}}</td>
                    <td class="d-none d-sm-table-cell">{{agenda(row.item.agendas)}}</td>
                    <td  :style="color_estado(row.item.estado)">{{texto_estado(row.item)}}</td>
                    <td>
                        <v-btn class="mx-2" fab dark small color="primary" @click="editar(row.item.cod)">
                            <v-icon dark>edit</v-icon>
                        </v-btn>
                    </td>            
                   
                    </tr>
                </template>
            </v-data-table>
            <div v-if="num_filas==0"><h3 class="warning">{{$i18n.t('personanotienecitas')}}</h3></div>


        </v-card-text>
        <v-card-actions>
             <v-btn color="gray" @click="cerrar" class="ml-8"><v-icon color="red" class="mr-4" small>arrow_back_ios</v-icon>{{$t('cerrar')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import CitaEdit from '@/components/CitaEdit.vue';
//mport axios from "axios";
import {call_url, date_to_char, date_diff, initCap} from '@/library/misFunciones.js';

export default {
    name: 'PersonaCitas',   
    components: {CitaEdit},

    computed: {
        ...mapState(['sesion']),
        num_filas() {return this.citas.length}
    },

    props:{
    //   value: Persona
    },

    data() {
        return {
            cod: null,
            nombre: '',
            apellidos: '',
            citas: [],
            visible: false,
            search: '',
            cabecera: [
                { text: this.$i18n.t('horacita'), value: 'horacita',sortable: false },
                { text: this.$i18n.t('duracion'), value: 'duracion',sortable: false},
                { text: this.$i18n.t('agenda'), value: 'agenda',class:'d-none d-sm-table-cell',sortable: false},
                { text: this.$i18n.t('estado'), value: 'estado',sortable: false},
                { text: this.$i18n.t('editar'), value: 'editar',sortable: false }],
                // { text: this.$i18n.t('editar'), value: 'editar',sortable: false }],

            estados: [{cod:'P',texto:this.$i18n.t('pendiente'),image:'paciente.gif',color:'black'},{cod:'D',texto:this.$i18n.t('dudosa'),image:'pacienteDudoso.gif',color:'tomato'},
                      {cod:'E',texto:this.$i18n.t('enespera'),image:'pacienteEspera.gif',color:'gray'},{cod:'T',texto:this.$i18n.t('atendida'),image:'atendida.png',color:'green'},
                      {cod:'A',texto:this.$i18n.t('anulada'),image:'pacienteAnulado.gif',color:'red'},{cod:'N',texto:this.$i18n.t('noacude'),image:'pacienteTachado.gif',color:'red'}],

        }
    },
    methods: {
        ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError','mostrarMensaje']),

        async abrir(cod,nombre,apellidos){
        //   try{
            // this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
            this.cod = cod;
            this.nombre = nombre;
            this.apellidos = apellidos;
            if (this.consulta()) {
                this.visible = true;
                setTimeout(this.foco,100);                
            }
        //     const data = await call_url("/agendab/getPersonaCitas.php",{"cod_persona":this.cod});
        //     if (data.resul==="OK") {
        //         this.citas = data.citas;
        //         this.visible = true;
        //         setTimeout(this.foco,100);                
        //     }
        //     else this.mostrarError(data.mensaje);

        //   }
        //   catch(ex) {
        //     console.log(ex);
        //     this.mostrarError(ex.messaje);
        //   }
        //   finally {
        //     this.ocultarLoading();
        //   }

        },

        async consulta() {
          try{
            this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
            const data = await call_url("/agendab/getPersonaCitas.php",{"cod_persona":this.cod});
            if (data.resul==="OK") {
                this.citas = data.citas;
                return true;
            }
            else this.mostrarError(data.mensaje);

          }
          catch(ex) {
            console.log(ex);
            this.mostrarError(ex.messaje);
          }
          finally {
            this.ocultarLoading();
          }
          return false;
        },

        editar(cod) {
            this.$refs.citaEdit.editar(cod);
        },
        

        buscaTabla(value, search, item) {
        //console.log(item)
        //return (item.nombre+' '+item.apellidos).toUpperCase().indexOf(search.toUpperCase())>=0;
            return true;
        },

        fecha_cita(fechaHora) {
            return date_to_char(fechaHora,"dd/mm/yyyy");
        },
        hora_cita(fechaHora) {
            return date_to_char(fechaHora,"hh:mi");
        },
        duracion(fecha,fecha_fin) {
            return date_diff(fecha,fecha_fin,"minute")+" "+this.$i18n.t('minutos');
        },
        agenda(agendas) {
            let resul="";
            for (let i=0;i<agendas.length;i++) {
                if (resul!=="") resul +="\n";
                resul +=agendas[i]
            }            
            return resul;
        },
        texto_estado(registro) {
            // console.log("registro",registro)
            for (let i=0;i<this.estados.length;i++) {
                // if (this.estados[i].cod==registro.estado) return this.estados[i].texto;
                if (this.estados[i].cod==registro.estado) {
                    if (registro.estado=="A" && registro.motivoAnulacion) return this.estados[i].texto + " (" +initCap(registro.motivoAnulacion)+")";
                    else return this.estados[i].texto
                };
            }
        },
        color_estado(estado){
            for (let i=0;i<this.estados.length;i++) {
                if (this.estados[i].cod==estado) return "color:"+this.estados[i].color;
            }
            // return "color:red";
        },

       
        cerrar(){
            this.visible = false;
        },
      
    },


 

}

</script>

<style>


</style>
