<template>
    <v-row>
       <CitaEdit ref="citaEdit" @change="getCitasIntervalos" />
       <ColEdit ref="colEdit" @change="getCitasIntervalos" />
       <Dia v-for="(item,index) in sesion.intervalos" :value="item.dia" :horas="item.horas" :num_dias="sesion.intervalos.length"
            :id="'divDia'+index" :ref="'divDia'+index" :key="item.indice" :citas="sesion.citas[index]" :colisiones="sesion.colisiones[index]"
            :marcas="sesion.marcas[index]" @click="click" />
    </v-row>
</template>

<script>
// @ is an alias to /src

import {mapState, mapMutations, mapActions} from 'vuex';
import Dia from '@/components/Dia.vue';
import CitaEdit from '@/components/CitaEdit.vue';
import ColEdit from '@/components/ColEdit.vue';
import {tienePermiso,call_url, date_diff, trunca_fecha, existe_marca2, elimina_marca} from '@/library/misFunciones.js';
import Vue from 'vue'

export default {
  name: 'Agenda',
  components: {Dia, CitaEdit, ColEdit},
  data:() =>({
    contador_inactividad: 0,
    timer:null,
    // teclaPulsadaTime:0,
  }),
  computed: {
    ...mapState(['sesion']),
  },
  methods: {
    ...mapMutations(['mostrarMensaje','mostrarError','mostrarLoading', 'ocultarLoading']),
    ...mapActions(['getCitasIntervalos']),
    async click(event,hora) {    
      //MARCADOR ACTIVO
      if (this.sesion.marcador) {  // Si está el marcador activo pinto
        try{
          if (this.sesion.agenda.tipo!=="S") {
            this.mostrarError(this.$i18n.t('mardadoressoloagendaprofesional'));
            return;
          }
          // console.log("marcador")
          // console.log(this.sesion.marcador)
          let marca;
          if (this.sesion.marcador.nombre==="Eliminador") {
            if (!existe_marca2(this.sesion.marcas,hora.inicio,hora.fin)) {
              // console.log("No existe marca")
              return;
            } 
            marca = {color:'Eliminador',agenda:this.sesion.agenda.cod, fecha:hora.inicio,fecha_fin:hora.fin};          
          }
          else if (existe_marca2(this.sesion.marcas,hora.inicio,hora.fin)===this.sesion.marcador.color) {
            // console.log("mismo color elimino") 
            marca = {color:'Eliminador',agenda:this.sesion.agenda.cod, fecha:hora.inicio,fecha_fin:hora.fin};          
          }
          else {
            marca = {color:this.sesion.marcador.color,agenda:this.sesion.agenda.cod, fecha:hora.inicio,fecha_fin:hora.fin};         
          }
         
          this.mostrarLoading(this.$i18n.t('grabando')+'...');
          const data = await call_url("/agendab/grabaMarca.php",marca);
          if (data.resul==="OK") { 
            if (marca.color==="Eliminador")   
              elimina_marca(this.sesion.marcas,hora.inicio,hora.fin)
            else
              this.sesion.marcas[date_diff(this.sesion.intervalos[0].dia, trunca_fecha(marca.fecha),"day")].push(marca)
          }
          else this.mostrarError(data.mensaje);
        }
        catch(error) {
          console.log(error);
          this.mostrarError(error.response.data);
        }
        finally {
          this.ocultarLoading();
        }

      }   
      //COL
      else if (hora.m_col){ //Compruebo si es una cita sin confirmar paciente
        //Llamo a componente de edición de cita on line
        // console.log('cita pendiente de validar');
        //Compruebo si tiene permiso para consultar pacientes
        if (tienePermiso('PER_GESTIONPACIENTES_CT'))
           this.$refs.colEdit.editar(hora);
        else
          this.mostrarError(this.$i18n.t('citaprovienecitaonline'))
      }
      //EDICION DE CITA
      else if (hora.cod) {
        this.$refs.citaEdit.editar(hora.cod);
      }
      //NUEVA CITA
      else {
        let agenda;
        if (this.sesion.modo_efectivo=="columna") {
          agenda = this.calculaAgendaSegunPosicionClick(event.clientX,(document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop)+event.clientY);
        }
        else agenda = this.sesion.agenda;
        this.$refs.citaEdit.nuevo(hora,agenda);
      }

    },
    calculaAgendaSegunPosicionClick(x,y){
      for (let i=0; i<7; i++) {
        let div = document.getElementById("divDia"+i);
        //console.log(div)
// offsetHeight: 530
// offsetLeft: 0
// offsetTop: 530
// offsetWidth: 476
        //console.log(div)
        // console.log(x+","+y+" div.offsetLeft:"+div.offsetLeft + " div.offsetWidth:"+div.offsetLeft+div.offsetWidth +" div.offsetTop:"+div.offsetTop +" div.offsetHeight:"+div.offsetTop+div.offsetHeight)
        if (div && x>=div.offsetLeft && x<div.offsetLeft+div.offsetWidth && y>=div.offsetTop && y<div.offsetTop+div.offsetHeight) {
          // console.log(div)
          //console.log("ancho_dia:"+this.$refs[div.id])
          // console.log("div.clientWidth:"+div.clientWidth + " this.sesion.agendas_dibujo.length:" +this.sesion.agendas_dibujo.length)
          const ancho_columna = (div.clientWidth-32)/this.sesion.agendas_dibujo.length;
          // console.log("encontrado en "+div.id + " ancho_columna:"+ancho_columna+" x/ancho_columna:"+x/ancho_columna)
          //Margen donde se pone la hora de los intervalos horarios
          return this.sesion.agendas_dibujo[Math.floor(((x % div.clientWidth)-32)/ancho_columna)]
        }
      }

    },
    resetContadorInactividad(){
      this.contador_inactividad = 0;
    },
    async incrementaContadorInactividad(){     
      // console.log("incrementaContadorInactividad")
      if (this.sesion.mostrar_empresa) return;

      if (this.contador_inactividad<1000) this.contador_inactividad += 1;
      // console.log("incrementaContadorInactividad2")
      if (this.sesion.rutaActual!=='agenda') return;
      // console.log("incrementaContadorInactividad3")
      if (this.sesion.contador_consulta<1000) this.sesion.contador_consulta++;
      if (this.contador_inactividad>60) return; //Si está inactivo no hago nada
      // console.log("incrementaContadorInactividad4:"+this.sesion.contador_consulta)
      if (this.sesion.contador_consulta>30) {
        this.sesion.contador_consulta = 0;
        // console.log("getUltimaActualizacion")
        // console.log('ultima:'+this.sesion.ultima_actualizacion)
        const data = await call_url("/agendab/getUltimaActualizacion.php");
        if (data.resul==="OK" && data.codigo!==this.sesion.ultima_actualizacion) {
          // console.log('data.codigo:'+data.codigo);
          this.getCitasIntervalos();
        }
      }
    },
    resize_window() {
      this.sesion.ancho_pantalla = window.innerWidth;
    },
    

  },
  mounted() {
    this.sesion.rutaActual = "agenda";
    window.addEventListener("mousemove", this.resetContadorInactividad);
    this.timer = setInterval(this.incrementaContadorInactividad,1000);
    window.addEventListener('resize', this.resize_window);
    // window.addEventListener('keydown', this.teclaPulsada);
    // window.addEventListener('keypress', this.keypress);

  },
  unmounted() {
    window.removeEventListener("mousemove", this.resetContadorInactividad);
    clearInterval(this.timer);
    window.removeEventListener('resize', this.resize_window);
    // window.removeEventListener('keydown', this.teclaPulsada);
    // window.removeEventListener('keypress', this.keypress);
  },
}

</script>

<style scoped>


</style>