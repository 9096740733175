<template>
  <v-row justify="center">
   <v-dialog persistent max-width="500"  v-model="sesion.opciones_visible">
      <v-card>
        <v-card-title class="headline">
          <v-row><v-col cols="1"><v-icon>tune</v-icon></v-col>
          <v-col>Opciones</v-col></v-row>
        </v-card-title>
          
        <v-card-text>
            <v-btn-toggle v-model="vista"  mandatory color="orange" class="botonera">
                    <v-btn value="nueva"> <v-img :src="require('@/assets/anchoTotal.png')" height="40" /> </v-btn>
                    <v-btn value="columna" v-if="sesion.varias_agendas"> <v-img :src="require('@/assets/columnas.png')" height="40" /> </v-btn>
                    <v-btn value="clasica" v-if="!sesion.varias_agendas && sesion.agenda && sesion.agenda.tipo!=='R'"> <v-img :src="require('@/assets/vistaClasica.png')" height="40" /> </v-btn>
            </v-btn-toggle>
            <br/>
            <v-btn-toggle v-model="opcion"  mandatory color="orange" class="botonera">
                    <v-btn value="euro"> <v-img :src="require('@/assets/euroVerde2.png')" height="20" /> </v-btn>
                    <v-btn value="rgpd"> <v-img :src="require('@/assets/candadoRojo2.png')" height="25" /> </v-btn>
                    <v-btn value="urgencia"> <v-img :src="require('@/assets/alertaNaranja2.png')" height="20" /> </v-btn>
            </v-btn-toggle>

            <v-slider v-model="num_dias" :label="$t('numerodias')" thumb-color="red" thumb-label="always" min="1" max="7"  dense thumb-size="17" class="slider"></v-slider>
            <v-checkbox v-model="mostrar_anuladas" label="Mostrar citas anuladas en la agenda" value="1" class="micheck"></v-checkbox>
            <v-checkbox v-model="mostrar_noacude" label="Mostrar citas en estado 'No acude'" value="1" class="micheck"></v-checkbox>
            <v-checkbox v-model="mostrar_mayusculas" label="Mostrar citas en mayúsculas" value="1" class="micheck"></v-checkbox>
            <v-checkbox v-model="mostrar_datos_cita" label="Mostrar datos de la cita" value="1" class="micheck"></v-checkbox>
            <v-checkbox v-model="mostrar_hora_inicio" label="Mostrar hora de inicio de la cita (vista nueva)" value="1" @click="clickhorainicio" class="micheck"></v-checkbox>
            <v-checkbox v-model="mostrar_hora_fin" label="Mostrar hora de fin de la cita (vista nueva)" value="1" class="micheck" :disabled="!mostrar_hora_inicio"></v-checkbox>



            

        </v-card-text>
        <v-card-actions>
             <v-btn color="primary" @click="graba"><v-icon class="mr-4" >check</v-icon>{{$t('aceptar')}}</v-btn>
             <v-btn color="gray" @click="sesion.opciones_visible=false" class="ml-8"><v-icon color="red" class="mr-4" small>arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';


export default {
    name: 'Opciones',   
    computed: {
        ...mapState(['sesion']),
    },
    data() {
        return {
            //visible: false,
            vista: '',
            opcion: '',
            num_dias: 1,
            mostrar_anuladas: false,
            mostrar_noacude: null,
            mostrar_mayusculas: false,
            mostrar_datos_cita: false,
            mostrar_hora_inicio: false,
            mostrar_hora_fin: false,
        }
    },
    methods: {
      ...mapActions(['getCitasIntervalos']),
      graba(){
          //this.setOpciones({num_dias:this.num_dias});
          this.sesion.vista = this.vista;
          this.sesion.opcion = this.opcion;
          this.sesion.num_dias = this.num_dias;
          this.sesion.mostrar_anuladas=this.mostrar_anuladas;
          this.sesion.mostrar_noacude=this.mostrar_noacude;
          this.sesion.mostrar_mayusculas=this.mostrar_mayusculas;
          this.sesion.mostrar_datos_cita=this.mostrar_datos_cita;
          this.sesion.mostrar_hora_inicio=this.mostrar_hora_inicio;
          this.sesion.mostrar_hora_fin=this.mostrar_hora_fin;

          this.sesion.opciones_visible = false;
          this.getCitasIntervalos();          
      },
      show(){
         this.$nextTick(() => {
          this.vista = this.sesion.vista;
          this.opcion = this.sesion.opcion;
          this.num_dias = this.sesion.num_dias;
          this.mostrar_anuladas = this.sesion.mostrar_anuladas;
          this.mostrar_noacude = this.sesion.mostrar_noacude;
          // console.log("mostrar_noacude:"+this.mostrar_noacude)
          this.mostrar_mayusculas=this.sesion.mostrar_mayusculas;
          this.mostrar_datos_cita=this.sesion.mostrar_datos_cita;
          this.mostrar_hora_inicio=this.sesion.mostrar_hora_inicio;
          this.mostrar_hora_fin=this.sesion.mostrar_hora_fin;
          this.sesion.opciones_visible = true;
         });

      },
      clickhorainicio() {
        if (!this.mostrar_hora_inicio && this.mostrar_hora_fin) this.mostrar_hora_fin = false;
      }
    },
    // mounted(){
    //   console.log("mounted mostrar_noacude:"+this.mostrar_noacude)
    // }

}

</script>

<style scoped>
  .slider {
    margin-top: 20px;
    margin-bottom: -10px;
  }
  .slider >>> label {
    font-size: 12px !important;   
  }
  .botonera {
    margin-bottom: -10px;
  }
  .micheck >>> label {
    font-size: 12px !important;   
  }
  .micheck {
    margin-top: 0px;
    margin-bottom: -20px;
  }


</style>
