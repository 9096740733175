

<template>
    <!-- <v-row class="rowHora" :style="{'height':height+'px','background':color_fila()}" @click="$emit('click')" >
        <v-col cols="1" class="colHora ma-0 pa-0">{{etiqueta}}</v-col>
    </v-row> -->
    <div class="rowHoraNueva" :style="estilo_hora" @click="$emit('click',$event,hora)">{{etiqueta}}</div>
</template>

<script>

import {mapState} from 'vuex';
import {date_to_char, char_to_date, date_diff} from '@/library/misFunciones.js';


export default {
    name: 'HoraNueva',   
    computed: {
        ...mapState(['sesion']),
        
        sm()  { if (this.num_dias>=2) return 2; },
        md()  { if (this.num_dias>=3) return 2; },
        lg()  { if (this.num_dias>=4) return 3; },
        etiqueta() {
            //Cuando el intervalo horario es tan pequeño que no cabe para pintar la hora: pintar solo las horas punta
            if (this.pixel_hora<35 && date_to_char(this.hora.inicio,"mi")!=="00") return "";
            if (this.hora.reservable) return date_to_char(this.hora.inicio,"hh:mi");
            else return "";
        },
        estilo_hora(){
            const top = this.altura_cabecera_dia + date_diff(char_to_date(this.hora_inicio_dia),char_to_date(this.hora.inicio),"minute") * this.pixel_hora/60;
            const altura = date_diff(char_to_date(this.hora.inicio),char_to_date(this.hora.fin),"minute") * this.pixel_hora /60;                      
            let zorder = "";
             if (this.pixel_hora<35 && date_to_char(this.hora.inicio,"mi")!=="00") zorder = "z-index:2;"
            let bgcolor;
            if ((this.num_fila % 2)===0) bgcolor = "ffffff";
            else bgcolor = "f0f0f0";
            return "left:0px; top:"+top+"px; height:"+altura+"px; background:#"+bgcolor+zorder;            


            // let bgcolor = existe_marca(this.marcas,this.hora.inicio,this.hora.fin) ;
            // console.log("bgcolor:",bgcolor)            
            // // bgcolor="ff0000"
            // if (bgcolor==="") {
            //     if ((this.num_fila % 2)===0) bgcolor = "ffffff";
            //     else bgcolor = "f0f0f0";
            // }
            // return "left:0px; top:"+top+"px; height:"+altura+"px; background:#"+bgcolor;



        }


    },
    data() {
        return {
        }
    },
    props:{
        altura_cabecera_dia: Number,
        hora: {},
        pixel_hora:  Number,
        num_dias: Number,
        num_fila: Number,
        hora_inicio_dia: String,
        //marcas: Array,
    },
    methods: {
      
    },
    watch: {
    },
    mount() {
    }

}

</script>

<style scoped>

.rowHoraNueva {
    font-size: 10px;
    border-bottom: 0.1px solid #d0d0d0;
    position: absolute;
    width: 100%;
    padding-left: 2px;
    overflow: visible;
}
/* .colHora {
    font-size: 11px;
    color: #505050;
    Xmargin-left:-10px;
    Xmargin-top:-15px;
    overflow: visible;
}
 */



</style>
