<template>
  <v-row justify="center">
   <v-dialog persistent max-width="500"  v-model="visible">
      <v-card>
        <v-card-title class="headline">
          <v-row><v-col cols="2"><v-img src="@/assets/logo.png" max-height="35" max-width=35></v-img></v-col>
          <v-col>{{$t('agendamovil')}}</v-col></v-row></v-card-title>
          
        <v-card-text>
          <v-form v-model='formValid'>
            <br/>{{$t('seleccioneusuario')}}:<br/>

            <v-select v-model="usuario" class="autocompleto" :items="usuarios"  item-text="nombre" return-object dense outlined></v-select>

          </v-form>

        </v-card-text>
        <v-card-actions>
             <v-btn color="primary" @click="setUsuario" :disabled="!formValid"><v-icon class="mr-4" >check</v-icon>{{$t('seleccionar')}}</v-btn>
             <v-btn  @click="cancelar" class="ml-8"><v-icon color="red" class="mr-4">arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import {call_url} from '@/library/misFunciones.js';


export default {
    name: 'Login',   
    computed: {
        ...mapState(['sesion']),
    },
    data() {
        return {
            usuario: '',
            usuarios: [],
            formValid: false,
            visible: false,
        }
    },
    methods: {
      ...mapMutations(['setEmpresa','mostrarError']),
    
      cancelar() {
        //this.sesion.mostrar_usuario = false;
        this.visible = false;
      },
      async getUsuarios() {
          const objeto = await call_url("/agendab/getUsuarios.php");
          if (objeto.resul==="OK") {
            this.usuarios = objeto.usuarios;
            return true;
          }
          else {
            this.mostrarError(this.$i18n.t(objeto.mensaje));  
            return false;
          }
      },
      async setUsuario() {
        // console.log(this.usuario)
          const objeto = await call_url("/agendab/setUsuario.php",{usuario:this.usuario.cod});
        //   console.log("respuesta",objeto)
          if (objeto.resul==="OK") {
            // this.usuarios = objeto.usuarios;
            this.sesion.cod_usuario = objeto.cod_usuario;
            this.sesion.nombre_usuario = objeto.nombre_usuario;
            this.visible = false;
          }
          else this.mostrarError(this.$i18n.t(objeto.mensaje));  
      },
      async show() {
        if (await this.getUsuarios()) {
            this.visible =true;
        }

      }


    },
   

}

</script>

<style>

</style>
