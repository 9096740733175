<template>
  <div class="autocomplete">
    <div class="input-container" style="margin-top:-23px">
    <label for="text" :class="{ 'disabledLabel': disabled }">{{label}}</label>
    <input
      type="text"
      :disabled="disabled" 
      v-model="search"      
      @focus="onInput"
      @keydown.down.prevent="navigate(1)"
      @keydown.up.prevent="navigate(-1)"
      @keydown.enter.prevent="select(suggestions[selectedIndex])"
      @blur="ocultar"
      ref="searchInput" 
    />
    </div>
    <!-- Sugerencias filtradas -->
    <ul v-if="showSuggestions && suggestions.length > 0" class="suggestions" @mousedown.prevent>
      <li v-for="(suggestion, index) in suggestions" :key="suggestion.cod" @click="select(suggestion)" :class="{ 'is-selected': index === selectedIndex }" >
        {{ getText(suggestion) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ControlAutoComplete',   
  props: {
    value: {
        type: Object,
        required: false,
    },
    items: {
        type: Array,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    getText: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      showSuggestions: false,
      suggestions: [],
      selectedIndex: -1,
    };
  },
  computed: {

  },
  
  watch: {

    value: { immediate: true, handler(newValue, oldValue) {
        // console.log(newValue)        
        if (newValue) {
          this.search = this.getText(newValue);
        }
        else {
          this.search = "";
        }
       }, 
    },
    search(newValue) {
      if (newValue) {
        // Filtrar solo en base al campo 'nombre' de cada objeto en la lista de items
        this.suggestions = this.items.filter((item) =>
          this.getText(item).toLowerCase().includes(newValue.toLowerCase())
        );
    //   } else {
    //     this.suggestions = [];
      }
      this.selectedIndex = -1; // Reiniciar selección
    },
  },
  methods: {
    onInput() {
      this.showSuggestions = true;
      this.showAllSuggestions();
      this.$refs.searchInput.select()      
    },
    navigate(direction) {
      if (this.suggestions.length > 0) {
        this.selectedIndex = (this.selectedIndex + direction + this.suggestions.length) % this.suggestions.length;
      }
    //   console.log("seleccionado:"+this.selectedIndex)
    },
    select(item) {
      this.search = this.getText(item); // Mostrar el 'nombre' en el campo de entrada
      this.showSuggestions = false;
      this.$emit('input',item)
      this.$emit('change',item)
      this.$refs.searchInput.blur();
    //   console.log("disparo evento change", item)
    },
    // change() {
    //   console.log("disparo evento change")
    //   this.$emit('input',item)
    //   this.$emit('change',item)
    // },
    ocultar() {
      if (!this.showSuggestions) return;
    //   console.log("value",value)
      if (this.selectedIndex==-1) {
        // console.log("no hay sleeccionado")
        this.search = ""; // Vaciar el campo de búsqueda
        // console.log("disparo evento change NULL")
        this.$emit('input',null);
        this.$emit('change',null);
      }      // Usar un pequeño timeout para permitir que el clic en el elemento de la lista se registre
      setTimeout(() => {
        this.showSuggestions = false;
      }, 50);
    //   console.log("value",this.value)
    },
    showAllSuggestions() {
       // console.log("value",this.value)
       this.suggestions = this.items; // Mostrar todos los elementos al enfocar
       if (this.value) {
         this.selectedIndex = this.suggestions.findIndex(suggestion => suggestion.cod === this.value.cod); // Resaltar el elemento previamente seleccionado
        //  console.log("encontradoooooooooooooo",this.selectedIndex,this.value.nombre+this.value.apellidos)
       } else {
         this.selectedIndex = 0; // Si no hay seleccionado, resaltar el primer elemento por defecto
       }
    },
    
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
}
input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-top:-20px;
  margin-bottom: 20px;
  border: none;
  height: 30px; 
  border-bottom: 1px solid #888;
  color:#333;
  padding-left: 1px;
}
input:disabled {
  color: #999; /* Color de texto gris para indicar que no se puede editar */
  border-bottom: 0.15em dotted #888;
}
.disabledLabel {
  color: #999 /* Color de texto gris para la etiqueta */
}
label {
    font-size: 11px;
}




.suggestions {
  list-style-type: none;
  margin-top:-19px;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 300px; 
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 100;
  color:#333;
  font-size:12px;
}
.suggestions li {
  padding: 4px;
  cursor: pointer;
}
.suggestions li.is-selected {
  background-color: #f0f0f0;
}
</style>