<template>
    <v-dialog v-model="cargando.estado" persistent max-width="180px" :transition="false" >
         <v-card>
            <v-card-text class="text-center">
                <br/>
                <v-progress-circular indeterminate color="indigo darken-2"/>
                 <br/><br/>
                 {{cargando.texto }}
            </v-card-text>
        </v-card> 
    </v-dialog>

</template>

<script>

import {mapState} from 'vuex';


export default {
    name: 'Cargando',
    computed: {
        ...mapState(['cargando'])
    }

    
}
</script>

