<template>
<div :style="{color:colorInfo,fontSize:'12px'}">
        {{info}}
        <v-btn  @click="$emit('click')" class="ml-6" small  :disabled="!enabled"><v-icon class="mr-4">smartphone</v-icon>{{$t('veravisos')}}</v-btn> 

    <v-dialog max-width="550" v-model="visible">
        <v-card height="460">
            <v-card-text>
            <v-form ref='form' onsubmit='return false'>
            <ControlAutorizacion ref="autorizacionAviso" v-model="autorizacion" @click="editarAutorizacion" @input="autorizacionCambiada" :info="texto_autorizacion" />
            <v-divider></v-divider>
            <div v-if="autorizacion!=='N'">
                <v-radio-group v-model="cita.aviso.tipo" row :disabled="disabled" @change="compruebaForm">
                    <v-radio  dense value="S" class="miradio ml-2" >               
                    <template slot="label">
                        <img :src="require('@/assets/sms.gif')" class="mr-2" >
                        {{$t('enviarsmspersona')}}
                    </template>
                    </v-radio>

                    <v-radio  dense value="E" class="miradio ml-2" icon="cancel">
                    <template slot="label">
                        <img :src="require('@/assets/email.jpg')" class="mr-2">
                        {{$t('enviaremailpersona')}}
                    </template>
                    </v-radio>

                    <v-radio v-if="hay_whatsapp" dense value="W" class="miradio ml-2" icon="cancel">
                    <template slot="label">
                        <img :src="require('@/assets/whatsapp.png')" class="mr-2">
                        {{$t('enviarwhatsapppersona')}}
                    </template>
                    </v-radio>

                    <v-radio dense value="N" class="miradio ml-2" >
                    <template slot="label">
                        <v-icon color="red" class="mr-2">do_not_disturb</v-icon>
                        {{$t('noavisarpersona')}}
                    </template>
                    </v-radio>              
                </v-radio-group>   

                    <v-row v-if="cita.aviso.tipo=='S'">
                        <v-col cols="2" sm="1"><ControlTexto type="number" v-model="cita.aviso.horasSms" ref="horasSms" :obligatorio="true" :positivo="true" :entero="true" :size="2" maxlength="3" @change="compruebaForm" :disabled="disabled"/></v-col>
                        <v-col v-show="$vuetify.breakpoint.smAndUp" cols="5"> {{$t('horasantescitatelefono')}} </v-col>
                        <v-col cols="10" sm="5" ><div v-show="$vuetify.breakpoint.xsOnly">{{$t('horasantescitatelefono')}}</div>
                            <ControlTexto type="movil" v-model="cita.aviso.telefono" ref="telefono" :obligatorio="true" @change="compruebaForm" :size="19" maxlength="19" :disabled="disabled" :label="$t('telefono')" :placeholder="$t('introduzcatelefono')"/>
                        </v-col>
                    </v-row>
            
                    <v-row v-if="cita.aviso.tipo=='E'">
                        <v-col cols="2" sm="1"><ControlTexto type="number" v-model="cita.aviso.horasEmail" ref="horasEmail" :obligatorio="true" :positivo="true" :entero="true" :size="2" maxlength="3" @change="compruebaForm" :disabled="disabled"/></v-col>
                        <v-col v-show="$vuetify.breakpoint.smAndUp" cols="5"> {{$t('horasantescitaemail')}} </v-col>
                        <v-col cols="10" sm="5" ><div v-show="$vuetify.breakpoint.xsOnly">{{$t('horasantescitaemail')}}</div>
                            <ControlTexto type="email" v-model="cita.aviso.email" ref="email" :obligatorio="true" @change="compruebaForm" width="235" maxlength="50" :disabled="disabled" :label="$t('email')" :placeholder="$t('introduzcaemail')"/>
                    </v-col></v-row>

                    <v-row v-if="cita.aviso.tipo=='W'">
                        <v-col cols="2" sm="1"><ControlTexto type="number" v-model="cita.aviso.horasWhatsapp" ref="horasWhatsapp" :obligatorio="true" :positivo="true" :entero="true" :size="2" maxlength="3" @change="compruebaForm" :disabled="disabled"/></v-col>
                        <v-col v-show="$vuetify.breakpoint.smAndUp" cols="5"> {{$t('horasantescitawhatsapp')}} </v-col>
                        <v-col cols="10" sm="5" ><div v-show="$vuetify.breakpoint.xsOnly">{{$t('horasantescitawhatsapp')}}</div>
                            <ControlTexto type="movil" v-model="cita.aviso.telefonoW" ref="telefonoW" :obligatorio="true" @change="compruebaForm" :size="19" maxlength="19" :disabled="disabled" :label="$t('telefono')" :placeholder="$t('introduzcatelefono')"/>
                    </v-col></v-row>

                <v-textarea v-if="cita.aviso.tipo!=='N'" v-model="cita.aviso.mensaje"  outlined rows="3" :label="$t('textoaviso')" :error-messages="mensaje_textoAviso" class="mt-5 ml-1 mr-1" :disabled="disabled" @keyup="compruebaForm"/>
            </div>
            </v-form>
            </v-card-text>
            <v-card-actions  class="card-actions">
                <v-btn @click="aceptar" :disabled="!formValid" small><v-icon color="green" class="ml-4">done</v-icon>{{$t('aceptar')}}</v-btn>
                <v-btn @click="cerrar" small><v-icon color="red" class="ml-4">arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
            </v-card-actions>

        </v-card>
   </v-dialog>
</div>
</template>

<script>

import {mapState} from 'vuex';
import ControlTexto from '@/components/ControlTexto.vue';
import ControlAutorizacion from '@/components/ControlAutorizacion.vue';
import {getParametro,Cita} from '@/library/misFunciones.js';
 
export default {
    name: 'ControlAviso',   
    components: {ControlAutorizacion, ControlTexto},
    data() {
        return {
            cargando:false,
            visible: false,
            cita: new Cita({}),
            autorizacion: 'N',
            formValid: false,
   
        }
    },
    computed:  {
        ...mapState(['sesion']),

        hay_whatsapp() {
            return (getParametro("whatsapp")==="1");
        },
        
        texto_autorizacion(){
            //console.log("textoautortizacion")
            //console.log(this.cita)
            // console.log(this.cita.persona.autorizacionAviso)
            if (this.cita && this.cita.persona) {
               return this.cita.persona.getAutorizacionDescripcion();              
            }
            //console.log("fin textoautortizacion")
            return "";
        },
        mensaje_textoAviso(){
            //console.log("mensaje_textoAviso")
            return this.cita.aviso.getMensajeError();
        },
        disabled(){       
            if (this.cita.aviso && (this.cita.aviso.estado=='E' || this.cita.aviso.estado=='A')) return true;
            if (this.cita.estado=="E" || this.cita.estado=="T" || this.cita.estado=="N") return true;
            return false;
        }


    },
    props: {
        label: String,
        info: String,
        colorInfo: String,
        enabled: Boolean,
    },
    watch: {
    },        
    methods: {
    //   ...mapMutations(['mostrarError']),
     
        editarAutorizacion(){
            // console.log("editarAutorazion",this.cita.persona)
            this.$refs.autorizacionAviso.editar(this.cita.persona);
        },
        autorizacionCambiada() {
            // console.log("autorizacionCambiada:"+this.autorizacion)
            this.cita.persona.autorizacionAviso = this.autorizacion;
            this.cita.calculaAvisoAutomatico(false);
            this.compruebaForm();
            this.$emit('autorizacionCambiada',this.autorizacion);
        },
        editar(cita){      
            this.autorizacion = cita.persona.autorizacionAviso;
            this.cita=new Cita(cita) //en lugar de asignarlo de manera directa creo una instancia, para que las modificaciones sobre la copia no afecten a la instancia que controla el formulario de editarCita
            //Los relleno independientemente de que esté seleccionado ese modo de aviso o no
            if (this.cita.aviso.email==="") this.cita.aviso.email = this.cita.persona.getEmailAviso();
            if (this.cita.aviso.telefono==="") this.cita.aviso.telefono = this.cita.persona.getTelefonoAviso();
            if (this.cita.aviso.telefonoW==="") this.cita.aviso.telefonoW = this.cita.persona.getTelefonoAviso();
            if (this.cita.aviso.mensaje==="") this.cita.aviso.mensaje = this.cita.getMensajeAviso();
            if (this.cita.aviso.horasEmail==="") this.cita.aviso.horasEmail = getParametro("horasEmail");
            if (this.cita.aviso.horasSms==="") this.cita.aviso.horasSms = getParametro("horasSMS");
            if (this.cita.aviso.horasWhatsapp==="") this.cita.aviso.horasWhatsapp = getParametro("horasWhatsapp");
            this.visible = true;
        },
     
        aceptar() {
            this.cita.calculaAvisoSeleccionUsuario();
            this.$emit('input',this.cita);
            this.cerrar();
        },
       
        cerrar(){
            this.visible = false;
            this.formValid = false;
        },
        compruebaForm(){
            this.formValid = false
            setTimeout(this.compruebaForm2,5);
        },
        compruebaForm2(){
            if (this.cita.aviso.tipo==="E") {
                if (this.$refs.horasEmail.getError()) {
                    this.formValid = false;
                    return;
                };
                if (this.$refs.email.getError()) {
                    this.formValid = false;
                    return;
                };
            }
            else if (this.cita.aviso.tipo==="S") {
                if (this.$refs.horasSms.getError()) {
                    this.formValid = false;
                    return;
                };
                if (this.$refs.telefono.getError()) {
                    this.formValid = false;
                    return;
                };
            }
            else if (this.cita.aviso.tipo==="W") {
                if (this.$refs.horasWhatsapp.getError()) {
                    this.formValid = false;
                    return;
                };
                if (this.$refs.telefonoW.getError()) {
                    this.formValid = false;
                    return;
                };
            }
            else { //Cuando o se envia mensaje no se analiza el texto del mensaje
                this.formValid = true;
                return;
            }
            if (!this.cita.aviso.esMensajeCorrecto()) {
                this.formValid = false;
                return;
            }
            this.formValid = true;
        },
    },

}

</script>
<style scoped>
body {
    font-size: 8px;
}
.col {
    font-size: 12px !important;

}
.miradio >>> label {
    font-size: 12px !important;   
  }
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>