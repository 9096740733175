import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import '@/assets/micss.css';

// Vue.config.silent = true
// Vue.config.productionTip = true
Vue.config.silent = false
Vue.config.productionTip = false


const lang = localStorage.getItem('lang') || 'es';
axios.defaults.headers['Accept-Language'] = lang;
//axios.defaults.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-Custom-Tag';

Vue.use(VueAxios, axios)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app') 
