

<template>
    <div v-if="(sesion.mostrar_anuladas || cita.estado!=='A') && (sesion.mostrar_noacude || cita.estado!=='N')" class="cita" :style="estilo_cita" @click="click" > 
        <div v-if="sesion.mostrar_hora_inicio">{{texto_hora}}</div>
        <div><img class="imagen" :src="getImagen_tipocita(cita.tipo_cita,cita.estado)"  />
             <img class="imagen" :src="getImagen_opcion(cita.tipo_cita,cita.pagado,cita.rgpd,cita.urgencia,sesion.opcion)" /></div>
        <div v-for="(item,index) in getPalabras" :key="index" >{{item}}</div>

        <div class="ml-2"><v-img :src="getImagen_aviso(cita.estado_aviso)" min-width="17" max-height="17" max-width="15" /></div>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {date_to_char, char_to_date, date_diff, date_add, getImagen_tipocita, getImagen_opcion, getImagen_aviso} from '@/library/misFunciones.js';


export default {
    name: 'ControlCita',   
    computed: {
        ...mapState(['sesion']),
      
        texto_hora() {
            let texto;
            texto = date_to_char(char_to_date(this.cita.inicio),"hh:mi");
            if (this.sesion.mostrar_hora_fin) texto =texto+"-"+date_to_char(char_to_date(this.cita.fin),"hh:mi");
            return texto;
        },

        // altura_cita() {
        //     return this.pixel_hora * 100;
        // },
        estilo_cita(){
            
            let inicioCita; //En las citas de todo el día, el inicio es el inicio del horario del día. Si no hubiese le dejo las 00:00
            //Si es duracion todo el día
            if (date_to_char(char_to_date(this.cita.inicio),"hh:mi")==="00:00" && 
                date_diff(date_add(char_to_date(this.cita.inicio),1,"day"),char_to_date(this.cita.fin),"minute")===0) {
                inicioCita = this.hora_inicio_dia;
            }
            else inicioCita = this.cita.inicio;

            let top;
            // console.log("cita")
            // console.log(this.cita)
            if (this.cita.height==1440) //duracion todo el dia
              top = this.altura_cabecera_dia ;
            else 
              top = this.altura_cabecera_dia + date_diff(char_to_date(this.hora_inicio_dia),char_to_date(inicioCita),"minute") * this.pixel_hora/60;
            //const height = (date_diff(char_to_date(inicioCita),char_to_date(this.cita.fin),"minute") * this.pixel_hora /60)-2;
            //if (height<=20) height=20;
            let height;
            if (this.cita.height==1440) //duracion todo el dia
               height = this.alturaDia - this.altura_cabecera_dia ;
            else {
               height = (this.cita.height * this.pixel_hora /60)-2
               if (top+height>this.alturaDia) height = this.alturaDia - top;
            }
            let bgcolor = "#ffffff";
            if (this.cita.color_agenda) bgcolor = '#'+this.cita.color_agenda;
            let left,width;
            if (this.sesion.modo_efectivo==="columna") {
                //Miro el orden de la agenda de la cita
                let pos=0;
                //while (pos<this.sesion.agendas_dibujo.length && this.cita.agenda_nombre!==this.sesion.agendas_dibujo[pos].nombre) pos++;
                while (pos<this.sesion.agendas_dibujo.length){
                    //Vista por columnas de Agenda Compleja: debe coincidir la agenda
                    if (this.sesion.agenda.tipo==="C" && this.cita.agenda_nombre==this.sesion.agendas_dibujo[pos].nombre) break;
                    //Vista por columnas de Todos los recursos: debe coincidir el recurso
                    if (this.sesion.agenda.tipo==="T" && this.cita.recurso_nombre==this.sesion.agendas_dibujo[pos].nombre) break;
                    pos++;
                }
                //width=(document.getElementById('divDia0').clientWidth-34)/this.sesion.agendas_dibujo.length;
                width=(this.ancho_dia-34)/this.sesion.agendas_dibujo.length;
                left=32+((this.ancho_dia-34)/this.sesion.agendas_dibujo.length)*pos;
                // console.log("pos:"+pos+" left cita:"+left)
                // console.log(this.sesion)
                // console.log(this.cita)
            }
            else {               
                //width=(document.getElementById('divDia0').clientWidth-34)*this.cita.width/1000;
                width=(this.ancho_dia-34)*this.cita.width/1000;
                //left = 32+(document.getElementById('divDia0').clientWidth-34)*this.cita.left/1000;
                left = 32+(this.ancho_dia-34)*this.cita.left/1000;
                // console.log("left:"+this.cita.left)
                //console.log("left:"+document.getElementById('divDia0').clientWidth*this.cita.left)

            }

            let zorder = "";
            // console.log("this.cita.zorder:"+this.cita.zorder);
            if (this.cita.zorder) zorder = "z-index:"+this.cita.zorder+";";
            // console.log("left:"+left+"px; width: "+width+"px; top:"+top+"px; height:"+height+"px; background:"+bgcolor+"; color:#"+this.cita.color_cita+";"+zorder);

            return "left:"+left+"px; width: "+width+"px; top:"+top+"px; height:"+height+"px; background:"+bgcolor+"; color:#"+this.cita.color_cita+";"+zorder;
        },

        getPalabras() {
           let valor = this.cita.descripcion;
           if (this.sesion.mostrar_mayusculas) valor = valor.toUpperCase();
 
           return valor.split(' ');
           //return ['hola','mi','amigo']
        },


    },
    data() {
        return {
        }
    },
    props:{       
        altura_cabecera_dia: Number,
        cita: Object,
        hora_inicio_dia: String,
        pixel_hora: Number,
        ancho_dia: Number,
        alturaDia: Number,
    },
    methods: {
        date_to_char,
        getImagen_tipocita,
        getImagen_opcion,
        getImagen_aviso,


        click(event){
            this.$emit('click',event,this.cita)
        },
     
    },
    watch: {
    },
    mount() {
    }

}

</script>

<style>

.cita {
  border-style: dotted;
  border-width: 1px;
  position: absolute;
  overflow: hidden;
  border-color:#000000;
  min-height:12px;
  cursor: pointer;
  font-size: 11px;
  
}
.cita>div {
  font-size: 11px;
  float:left; 
  display: inline;
  margin-left: 2px;
  /* margin-bottom: 0px;
  margin-top: 0px; */
}

.imagen {
  max-height: 15px;
  overflow: hidden;
  margin-bottom: -4px;
}


</style>
