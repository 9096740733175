

<template>
<v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" ref="divDia" :id="id" :class="{'divDia':true}" @click="clicknuevo" >

    <div v-if="sesion.modo_efectivo=='clasica'">
        <v-row>
            <v-col :class="{'tituloDia':true,'diaActual':es_hoy(value)}">{{getTituloDia(value)}}</v-col>
        </v-row>
        <Hora v-for="(item,index) in horas"  :alturaDia="alturaDia" :num_horas="horas.length" :num_dias="num_dias" :ref="'divHora'+item.indice"  
             :key="index" :hora="item" :num_fila="index" :marcas="marcas" @click="click"></Hora>
    </div>
     <div v-if="sesion.modo_efectivo!=='clasica'">
        <v-row class="rowAgendas"><v-col :class="{'tituloDia':true,'diaActual':es_hoy(value)}">{{getTituloDia(value)}}</v-col></v-row>
        <v-row v-if="sesion.modo_efectivo==='columna'">
            <div v-for="(item,index) in sesion.agendas_dibujo" :key="index" class="colAgenda" :style="getStyleTituloAgenda(item,index)">
                {{getIniciales(item.nombre)}}  
            </div>
        </v-row>
        
        <HoraNueva v-for="(item,index) in horas" :hora_inicio_dia="hora_inicio_dia()" :altura_cabecera_dia="altura_cabecera_dia"
            :pixel_hora="pixel_hora" :num_dias="num_dias" :ref="'divHora'+item.indice" :hora="item" :key="item.indice" 
            :num_fila="index" @click="click"></HoraNueva>
        <ControlCita v-for="(item) in citas" :cita="item" :hora_inicio_dia="hora_inicio_dia()" :pixel_hora="pixel_hora" :key="1000+item.indice"
             :altura_cabecera_dia="altura_cabecera_dia" :ancho_dia="ancho_dia" :alturaDia="alturaDia" @click="click" />
        <ControlMarca v-for="(item,index) in marcas" :marca="item" :hora_inicio_dia="hora_inicio_dia()" :pixel_hora="pixel_hora" :key="'m'+index"
             :altura_cabecera_dia="altura_cabecera_dia" :ancho_dia="ancho_dia" @click="click" />
    </div> 
</v-col>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import Hora from '@/components/Hora.vue';
import ControlBoton from '@/components/ControlBoton.vue';
import HoraNueva from '@/components/HoraNueva.vue';
import ControlCita from '@/components/ControlCita.vue';
import ControlMarca from '@/components/ControlMarca.vue';
import {Cita, date_to_char, char_to_date, date_add, date_diff, es_hoy,getIniciales} from '@/library/misFunciones.js';


export default {
    name: 'Dia',   
    components: {Hora,HoraNueva, ControlCita, ControlBoton, ControlMarca},
    computed: {
        ...mapState(['sesion']),
        sm()  { if (this.num_dias>=2) return 6; },
        md()  { if (this.num_dias>=3) return 4; },
        lg()  { if (this.num_dias>=4) return 3; },
        xl()  { 
            if (this.num_dias>=4) return 3; 
        },
        ancho_dia() {
            // console.log("horas")
            // console.log(this.horas)
            const corrector=20;
            if (this.sesion.ancho_pantalla<600) return this.sesion.ancho_pantalla-corrector;  //xs
            else if (this.sesion.ancho_pantalla<960) { //sm
               if (this.num_dias>=2) return (this.sesion.ancho_pantalla-corrector)/2;
               else return this.sesion.ancho_pantalla-corrector;
            }
            else if (this.sesion.ancho_pantalla<1264) { //md
                if (this.num_dias>=3) return (this.sesion.ancho_pantalla-corrector)/3;
                else return (this.sesion.ancho_pantalla-corrector)/this.num_dias;
            }
            else if (this.sesion.ancho_pantalla<1904) { //lg
                if (this.num_dias>=4) return (this.sesion.ancho_pantalla-corrector)/4;
                else return (this.sesion.ancho_pantalla-corrector)/this.num_dias;
            }
            else { //xl
                if (this.num_dias>=4) return (this.sesion.ancho_pantalla-corrector)/4;
                else return (this.sesion.ancho_pantalla-corrector)/this.num_dias;
            }
        },
        // ancho_dia() {
        //     return this.$refs.divDia.clientWidth;
        // },
        altura_cabecera_dia(){ //cuando se dibuja por columnas la altura hay que sumarle el row con los nombres de las agendas
            if (this.sesion.modo_efectivo==='columna') return 50;
            else return 30;
        },
        pixel_hora() {
            try {
                const h1 = this.hora_inicio_dia();
                const h2 = this.hora_fin_dia();
                // console.log("h1",h1,"h2",h2)
                let valor;
                if (h1 && h2) {               
                    //valor = Math.round((this.alturaDia-this.altura_cabecera_dia)*60/date_diff(char_to_date(h1),char_to_date(h2),"minute"));
                    valor = (this.alturaDia-this.altura_cabecera_dia)*60/date_diff(char_to_date(h1),char_to_date(h2),"minute");
                    // console.log("valor",valor,"arriba",(this.alturaDia-this.altura_cabecera_dia)*60,"abajo",date_diff(char_to_date(h1),char_to_date(h2),"minute"))
                }
                else return 48;
                if (valor>100) return 100;
                return valor;
                // console.log("pixel_hora:"+this.pixel_hora )

            }
            catch(e) {
                console.error(e)
                return 48;
            }
        }

        
      
    },
    data() {
        return {
            alturaDia: 0,
            timer_ajusta: 0,
            timer: 0,
            conta:0,
        }
    },
    props:{
        id: String,
        value: String,
        num_dias: Number,
        horas: Array,
        citas: Array,
        colisiones: Array,
        marcas: Array,
    },
    methods: {
        ...mapMutations(['atras_fecha','adelante_fecha']),
        es_hoy,
        getIniciales,
        getTituloDia(fecha) {
            let cad="";
            if (es_hoy(fecha)) cad=this.$i18n.t('hoy')+", ";
            return cad + date_to_char(fecha,"day dd/mm/yyyy");
        },
        getStyleTituloAgenda(item,index){
            //if (!document.getElementById('divDia0')) return "";
            //const left = 32+((document.getElementById('divDia0').clientWidth-34)/this.sesion.agendas_dibujo.length)*index;
            const left = 32+((this.ancho_dia-34)/this.sesion.agendas_dibujo.length)*index;
            //const width = (document.getElementById('divDia0').clientWidth-34)/this.sesion.agendas_dibujo.length;
            const width = (this.ancho_dia-34)/this.sesion.agendas_dibujo.length;
            return 'height:20px;left:'+left+'px; width:'+width+'px; background-color:#'+item.colorCitas
        },

        hora_inicio_dia() {
            let minHora = '';
            //En principio la hora de inicio del día es el primer intervalo
            if (this.horas && this.horas.length>0) {
                minHora = this.horas[0].inicio;
            }

            //Si hay una cita anterior me quedo con la hora(que no sea de todo el día) 
            if (this.citas && this.citas.length>0){
                //Busco la primera cita anterior al horario que no sea todo el día
                let i = 0;
                while (i<this.citas.length && (minHora==="" || this.citas[i].inicio<minHora)) {
                    if (date_diff(char_to_date(this.citas[i].fin),date_add(char_to_date(this.citas[i].inicio),1,"day"),"minute")!==0) {
                        minHora = this.citas[i].inicio;
                        break;
                    }
                    i++;
                }
            } 

            //Si no he podido calcular minHora: 09 
            if (minHora===""){
                // console.log("vlaue_:"+this.value)
                minHora = date_to_char(date_add(char_to_date(this.value),9,"hour"),"yyyy-mm-ddThh:mm:ss.000Z");
            } 


            // if (minHora=='' && (typeof this.citas!=="undefined") && this.citas.length>0) minHora = this.citas[0].inicio;
            
            // if (this.citas && this.citas.length>0){
            //     //Busco la primera anterior al horario que no sea todo el día
            //     let i = 0;
            //     while (i<this.citas.length && this.citas[i].inicio<minHora) {
            //         if (date_diff(char_to_date(this.citas[i].fin),date_add(char_to_date(this.citas[i].inicio),1,"day"),"minute")!==0) {
            //             minHora = this.citas[i].inicio;
            //             break;
            //         }
            //         i++;
            //     }
            // } 
            // console.log("hora_inicio_dia devuelvo:"+minHora)

            // return date_to_char(minHora,"yyyy-mm-ddThh:mm:ss.000Z");
            return minHora;

        },
        hora_fin_dia() {
           
            let maxHora = '';

            //En principio la hora de finicio del día es el ultimo intervalo
            if (this.horas && this.horas.length>0) {
                maxHora = this.horas[this.horas.length-1].fin;
            }

            //Si hay una cita posterior me quedo con la hora(que no sea de todo el día) 
            if (this.citas && this.citas.length>0){
                let i = 0;
                while (i<this.citas.length) {
                    if (maxHora==="" || this.citas[i].fin>maxHora) {
                        if (date_diff(char_to_date(this.citas[i].fin),date_add(char_to_date(this.citas[i].inicio),1,"day"),"minute")!==0) {
                            maxHora = this.citas[i].fin;
                        }
                    }
                    i++;
                }
            }

            //Si no he podido calcular maxHora: 15
            if (maxHora===""){
                // console.log("vlaue_:"+this.value)
                maxHora = date_to_char(date_add(char_to_date(this.value),15,"hour"),"yyyy-mm-ddThh:mm:ss.000Z");
            } 


            // if (maxHora=='' && (typeof this.citas!=="undefined") && this.citas.length>0) maxHora = this.citas[this.citas.length-1].fin;
            
            // if (this.citas && this.citas.length>0){
            //     //Busco la primera posterior al horario que no sea todo el día
            //     let i = 0;
            //     while (i<this.citas.length) {
            //         if (this.citas[i].fin>maxHora) {
            //             if (date_diff(char_to_date(this.citas[i].fin),date_add(char_to_date(this.citas[i].inicio),1,"day"),"minute")!==0) {
            //                 maxHora = this.citas[i].fin;
            //                 break;
            //             }
            //         }
            //         i++;
            //     }
            // }

            // console.log("hora_fin_dia devuelvo:"+maxHora)
            
            // return date_to_char(maxHora);
            // return date_to_char(maxHora,"yyyy-mm-ddThh:mm:ss.000Z");
            return maxHora;


        },
      


        click(event,hora){
            // console.log("click:",event);
            if (event) event.stopPropagation() //para que no se ejecute clicknuevo en el componente padre
            this.$emit('click',event,hora);
        },  
        clicknuevo(event) {
            // console.log("clicknuevo");
            // console.log("value:"+this.value)
            // console.log("value:",date_add(char_to_date(this.value),10,"hour"))
            this.$emit('click',event,{inicio:date_add(char_to_date(this.value),9,"hour"),fin:date_add(char_to_date(this.value),10,"hour")});
        },
        resize(){
            this.calcula_altura();
        },
        calcula_altura(){
            this.alturaDia = this.$refs.divDia.clientHeight;
        },
        rota_citas_superpuestas() {
            // return;
            if (this.sesion.modo_efectivo!=="columna") return;
            if (!this.citas) return;
            // console.log("entro en rota_citas")
            //Calculo si existe colision para cada cita
            // let grupos_colisiones = [];
            for (let i=0; i<this.citas.length; i++) {
                if (this.citas[i] && this.citas[i].num_colisiones==0) {
                    for (let j=i+1; j<this.citas.length; j++) {
                        // console.log(this.sesion.agenda)
                        if (this.citas[j] && Cita.colision(this.citas[i],this.citas[j],this.sesion.agenda.tipo==="T")) {
                            this.citas[i].num_colisiones = 1;
                            this.citas[j].num_colisiones = 1;
                            // console.log("encontrada colision")
                            //Incluyo el grupo de colisiones si no está ya incluido
                            let posi = -1;
                            let posj = -1;
                            for (let x=0; x<this.colisiones.length; x++) {
                                for (let y=0; y<this.colisiones[x].length; y++) {
                                    if (this.colisiones[x][y]===i) posi=y;
                                    if (this.colisiones[x][y]===j) posj=y;
                                }
                            }
                            if (posi<0 && posj<0) {
                                // console.log("grabo colision",i,j)
                                this.colisiones.push([i,j]); //si no he encontrado ningun grupo para i ni para j --> creo un grupo un nuevo con los dos
                            }
                            else if (posi>=0 && posj<0) this.colisiones[posi].push(j); //si he encontrado un grupo con i --> añado en ese grupo a j
                            else if (posi<0 && posj>=0) this.colisiones[posj].push(i); //si he encontrado un grupo con j --> añado en ese grupo a i

                        }
                    }
                }                
            }
         
            //Dentro de cada grupo voy rotando el zorder
            for (let x=0; x<this.colisiones.length; x++) {
                for (let y=0; y<this.colisiones[x].length; y++) {

                    if ((this.conta % this.colisiones[x].length)===y) {
                        this.citas[this.colisiones[x][y]].zorder = 2;
                        // console.log("pongo z order 2",this.colisiones[x][y]);
                    }
                    else {
                        this.citas[this.colisiones[x][y]].zorder = 1;
                        // console.log("pongo z order 1",this.colisiones[x][y]);

                    }
                }
            }
           
            if (this.citas.length>0) this.conta = (this.conta+1) % this.citas.length;
        }

    },
    watch: {
    },
    mounted() {
        this.calcula_altura();
    },
    created() {
        window.addEventListener("resize", this.resize);

        if (this.timer!==0) clearInterval(this.timer);
        this.timer = setInterval(this.rota_citas_superpuestas,700);
        //this.timer = setTimeout(this.rota_citas_superpuestas,700);
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    },
    updated() {
      

    }

}

</script>

<style >
.divDia {
  border-style: solid; 
  border-width: 1px;
  background: #f0f0f0;
  float:left; 
  display: inline;
  height: calc(100vh - 52px);
  position: relative;
}

.tituloDia {
    font-size: 13px;
    text-align: center;
    height: 30px;
    background: #d0d0d0;
    border-bottom: solid 1px #404040;
}
.diaActual {
    color: red;
}
.colAgenda {
  position: absolute;
  overflow: hidden;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
}

</style>
