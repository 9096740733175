<template>
  <v-row justify="center">
   <v-dialog persistent max-width="500"  v-model="sesion.mostrar_autenticacion">
      <v-card>
        <v-card-title class="headline">
          <v-row><v-col cols="2"><v-img src="@/assets/logo.png" max-height="35" max-width=35></v-img></v-col>
          <v-col>Bienvenido a Agenda Movil</v-col></v-row></v-card-title>
        <v-card-text>
          <v-form v-model='formValid'>
            <br/>Introduzca su usuario y contraseña:<br/>
            <v-row><v-col cols="11">
            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required />
            </v-col>
            <v-col cols="1"><v-icon class="mt-5">alternate_email</v-icon></v-col>
            </v-row>
            <v-row>
            <v-col cols="11">
              <v-text-field v-model="clave" :rules="[rules.required, rules.min]" :type="enClaro ? 'text' : 'password'"
                name="clave" label="Contraseña" hint="Mínimo de 5 caracteres" @keypress.enter="crearSesion" counter @click:append="enClaro = !enClaro">
                <!--<icono prefix="fa" :icon="icono_password" slot="append" @click="enClaro =!enClaro"></icono>-->
                
            </v-text-field></v-col>
            <v-col cols="1"><v-icon class="mt-5" @click="enClaro =!enClaro">{{icono_clave}}</v-icon></v-col>
            </v-row>

            <!--<div v-if="mostrarError">
              <br/>
            <v-alert dense outlined type="error">{{mensajeError}}</v-alert>
            </div>-->
          </v-form>

        </v-card-text>
        <v-card-actions>
             <v-btn color="primary" @click="crearSesion" :disabled="!formValid"><v-icon class="mr-4" >check</v-icon>{{$t('entrar')}}</v-btn>
             <!--<v-btn color="secondary" @click="toggleSesion" class="ml-8"><v-icon class="mr-4">email</v-icon>Recordar clave</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";
import {mapState, mapMutations} from 'vuex';
import {call_url} from '@/library/misFunciones.js';


export default {
    name: 'Login',   
    computed: {
        ...mapState(['sesion']),
        icono_clave() {
          if (this.enClaro) return "visibility_off";
          else return "visibility";
        }
    },
    data() {
        return {
            email: '',
            clave: '',
            emailRules: [
                v => !!v || 'No puede dejar el email vacío',
                v => /.+@.+\..+/.test(v) || 'Email introducido es incorrecto',
              ],
            enClaro: false,
            rules: {
                required: value => !!value || 'Introduzca una contraseña',
                min: v => v.length >= 5 || 'Mínimo 5 caracteres',
            },
            formValid: false,
        }
    },
    methods: {
      ...mapMutations(['mostrarLoading','ocultarLoading','iniciaSesion','mostrarError']),
    
      async crearSesion() {
        if (!this.formValid) return;
        try{
          this.mostrarLoading();
          //const objeto = (await axios.post(getServidor()+"/agendab/autentica.php",{'usuario': this.email, 'clave':this.clave })).data;      
          const objeto = await call_url("/agendab/autentica.php",{'usuario': this.email, 'clave':this.clave });
          if (objeto.resul==="OK") {
            // console.log('autenticacion correcta',objeto);
            axios.defaults.headers['X-Custom-Tag'] = objeto.tag;
            this.iniciaSesion(objeto);
          }
          else throw objeto.mensaje;
        }
        catch(error) {
          console.log(error);
          this.mostrarError(this.$i18n.t(error)); 
        }
        finally {
          this.ocultarLoading();
          this.clave = '';
        }
      },



    },

}

</script>

<style>

</style>
