<template>
  <v-row justify="center">
    <v-dialog persistent max-width="500" v-model="sesion.mostrar_logout">
      <v-card>
        <v-card-title class="cabeceraModal">
          <v-row><v-col cols="1"><v-icon color=red>power_settings_new</v-icon></v-col>
            <v-col>{{$t('deseacerrarsesion')}}</v-col>
          </v-row>
        </v-card-title>
          
        <v-card-text>

        </v-card-text>
        <v-card-actions>
             <v-btn color="error" @click="cerrarSesion(true)"><v-icon color="white" left>power_settings_new</v-icon>{{$t('cerrarsesion')}}</v-btn>
             <!-- <v-btn color="secondary" @click="close" class="ml-8"><v-icon class="mr-4">cancel</v-icon>{{$t('cancelar')}}</v-btn> -->
             <v-btn color="gray" @click="close" class="ml-8"><v-icon color="red" class="mr-4" small>arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations} from 'vuex';


export default {
    name: 'Logout',   

    computed: {
        ...mapState(['sesion']),
    },
    data() {
        return {
        }
    },
    
    methods: {
        ...mapMutations(['cerrarSesion']),
        close(){
           this.sesion.mostrar_logout = false;
        },
       

 },

 

}

</script>

<style>

</style>
