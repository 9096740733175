<template>
  <v-row justify="center">
   <v-dialog persistent max-width="500"  v-model="sesion.mostrar_empresa">
      <v-card>
        <v-card-title class="headline">
          <v-row><v-col cols="2"><v-img src="@/assets/logo.png" max-height="35" max-width=35></v-img></v-col>
          <v-col>{{$t('agendamovil')}}</v-col></v-row></v-card-title>
          
        <v-card-text>
          <v-form v-model='formValid'>
            <br/>{{$t('seleccioneempresa')}}:<br/>

            <v-select v-model="empresa" class="autocompleto" :items="sesion.empresas"  item-text="nombre" return-object dense outlined></v-select>

          </v-form>

        </v-card-text>
        <v-card-actions>
             <v-btn color="primary" @click="selecciona" :disabled="!formValid"><v-icon class="mr-4" >check</v-icon>{{$t('seleccionar')}}</v-btn>
             <v-btn color="secondary" @click="cerrarSesion(true)" class="ml-8"><v-icon class="mr-4">power_settings_new</v-icon>{{$t('cerrarsesion')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations} from 'vuex';


export default {
    name: 'Login',   
    computed: {
        ...mapState(['sesion']),
    },
    data() {
        return {
            empresa: '',
            rules: {
                required: value => !!value || 'Introduzca una contraseña',
                min: v => v.length >= 5 || 'Mínimo 5 caracteres',
            },
            formValid: false,
        }
    },
    methods: {
      ...mapMutations(['cerrarSesion','setEmpresa']),
    
      selecciona() {
        this.setEmpresa(this.empresa.cod);
      },

    },

}

</script>

<style>

</style>
