<template>
<div>


    <v-row><v-col>
        <v-text-field v-model="hora_texto" :label="label" class="mt-3 hora"  @click="click" @click:append="visible=true" 
        :clearable="clearable" @click:clear="hora='';minuto='';" readonly :disabled="disabled" :append-icon="append_icon" ></v-text-field>
        </v-col>
    </v-row>
    <v-dialog max-width="500" v-model="visible">
        <v-card>
            <!-- <v-time-picker  v-model="controlHoraValue" format="24hr" full-width :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55]" />          -->
            <div class="titulo">{{hora_previsualiza}}</div>
            <span style="margin-left:5px; font-size:14px;">Hora:</span><br/>
            <v-btn-toggle  v-model="hora" mandatory color="red" rounded >
                <table><tr>
                    <td><v-btn small value="00"> 00 </v-btn></td>
                    <td><v-btn small value="01"> 01 </v-btn></td>
                    <td><v-btn small value="02"> 02 </v-btn></td>
                    <td><v-btn small value="03"> 03 </v-btn></td>
                    <td><v-btn small value="04"> 04 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="05"> 05 </v-btn></td>
                    <td><v-btn small value="06"> 06 </v-btn></td>
                    <td><v-btn small value="07"> 07 </v-btn></td>
                    <td><v-btn small value="08"> 08 </v-btn></td>
                    <td><v-btn small value="09"> 09 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="10"> 10 </v-btn></td>
                    <td><v-btn small value="11"> 11 </v-btn></td>
                    <td><v-btn small value="12"> 12 </v-btn></td>
                    <td><v-btn small value="13"> 13 </v-btn></td>
                    <td><v-btn small value="14"> 14 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="15"> 15 </v-btn></td>
                    <td><v-btn small value="16"> 16 </v-btn></td>
                    <td><v-btn small value="17"> 17 </v-btn></td>
                    <td><v-btn small value="18"> 18 </v-btn></td>
                    <td><v-btn small value="19"> 19 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="20"> 20 </v-btn></td>
                    <td><v-btn small value="21"> 21 </v-btn></td>
                    <td><v-btn small value="22"> 22 </v-btn></td>
                    <td><v-btn small value="23"> 23 </v-btn></td>
                </tr></table>
            </v-btn-toggle>
            <br/><br/>
            <span style="margin-left:5px; font-size:14px;">Minuto:</span><br/>
            <v-btn-toggle  v-model="minuto" mandatory color="red" >
                <table><tr>
                    <td><v-btn small value="00"> 00 </v-btn></td>
                    <td><v-btn small value="05"> 05 </v-btn></td>
                    <td><v-btn small value="10"> 10 </v-btn></td>
                    <td><v-btn small value="15"> 15 </v-btn></td>
                    <td><v-btn small value="20"> 20 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="25"> 25 </v-btn></td>
                    <td><v-btn small value="30"> 30 </v-btn></td>
                    <td><v-btn small value="35"> 35 </v-btn></td>
                    <td><v-btn small value="40"> 40 </v-btn></td>
                    <td><v-btn small value="45"> 45 </v-btn></td>
                    </tr><tr>
                    <td><v-btn small value="50"> 50 </v-btn></td>
                    <td><v-btn small value="55"> 55 </v-btn></td>
                </tr></table>
            </v-btn-toggle>

            <br/><br/><br/><br/><br/>

            <div class="botonizquierda"><v-btn id="aceptarhora" text color="primary" @click="aceptar">{{$i18n.t('aceptar')}} </v-btn></div>
            <div class="botonderecha"><v-btn text color="error" @click="cerrar">{{$i18n.t('cancelar')}}</v-btn></div>
        </v-card>
   </v-dialog>
</div>
</template>

<script>

export default {
    name: 'ControlHora',   
    data() {
        return {
            visible: false,            
            hora_texto: '',
            // controlHoraValue: null
            hora: '',
            minuto:'',

           
        }
    },
    props: {
        label: String,
        value: String,
        disabled: Boolean,
        // multiploMinutos: {type: Number, required:false, default:0}, //indica que los minutos deben ser multiplo de esa cantidad
        clearable: {type: Boolean, required: false, default: true },
        required: {type: Boolean, required: false, default: false },
        mostrar_icono: {type: Boolean, required: false, default: true }
    },
    computed: {
      append_icon(){
        //if (window.innerWidth>500) return "schedule";
        if (this.mostrar_icono) return "schedule";
        else return ""
      },
      hora_previsualiza(){
          return this.hora.padStart(2,"0") + ":" + this.minuto.padStart(2,"0");
      }

    },
    watch: {
      value: { immediate: true, handler(newval, oldval) {
            this.hora=null;
            this.minuto=null;
            if (!newval) {
                // this.controlHoraValue = null;
                this.hora="";
                this.minuto="";
                this.hora_texto = "";
            }
            else {
              if (typeof newval =="string") {
                //   console.log("hay valor:"+newval)
                  //this.controlHoraValue = newval.substring(0,5);
                  this.hora = newval.substring(0,2);
                //   console.log(this.hora)
                  this.minuto = newval.substring(3,5);
                  this.hora_texto  = newval.substring(0,5);
              }
            }
         }, 
         hora: { immediate: true, handler(newval, oldval) {
            //  console.log("hora cambiada:"+newval)
         }}

      },        
    },    
    methods: {
        click() {
            this.visible=true;
            setTimeout(function(){ document.getElementById('aceptarhora').focus();},50);
        },

        aceptar() {
            const valorAnterior =  this.hora_texto;
            // if (this.controlHoraValue) this.hora_texto = this.controlHoraValue;
            if (this.hora && this.minuto) this.hora_texto = this.hora.padStart(2,"0") + ":" + this.minuto.padStart(2,"0");
            else this.hora_texto = "";
            this.visible=false;
            if (valorAnterior!==this.hora_texto) {
              this.$emit('input',this.hora_texto)
              this.$emit('change',this.hora_texto)
            }

        },
        cerrar(){
            this.visible = false;
        },
       
       
    },
   
}

</script>

<style>
  .botonizquierda {
      position: absolute;
      left:10px;
      bottom: 10px;
  }
  .botonderecha {
      position: absolute;
      bottom: 10px;
      right:20px;
  }
  .titulo {
      height:70px; 
      background-color:#5050f0; 
      font-size:40px;
      padding-top: 10px;
      text-align: center;
      color: #e0e0e0;
  }
  .hora{
      width: 62px;
 }

</style>
