<template>
<div :style="{color:colorInfo,fontSize:'12px'}">
        
        <v-row >
           <v-col cols="12" sm="4"><v-btn  @click="$emit('click')" small class="mt-2"><v-icon class="mr-4" color="blue">edit</v-icon>{{$t('autorizacion')}}</v-btn></v-col>
           <v-col cols="12" sm="8"><p v-html="info"></p></v-col>
        </v-row>
        

    <v-dialog max-width="550" v-model="visible">
        <v-card height="380">
            <v-card-text>
            <v-form ref='form' onsubmit='return false'>
            <v-radio-group v-model="autoriza">
                <v-radio  dense value="S" class="miradio ml-2" @change="compruebaForm">               
                <template slot="label">
                    <v-icon color="green" class="mr-2">thumb_up</v-icon>
                    {{$t('personaautorizarecibirrecordatorios')}}
                </template>
                </v-radio>

                <v-radio  dense value="N" class="miradio ml-2" @change="compruebaForm">
                <template slot="label">
                    <v-icon color="red" class="mr-2">thumb_down_alt</v-icon>
                    {{$t('personanoautorizarecibirrecordatorios')}}
                </template>
                </v-radio>                 
            </v-radio-group>   
            <v-divider></v-divider>
            <v-radio-group v-if="autoriza=='S'" v-model="siempre" >
                <v-radio dense value="S" class="miradio ml-2" @change="compruebaForm">               
                <template slot="label">
                    <img :src="require('@/assets/sms.gif')" class="mr-2" >
                    {{$t('siempreavisarporsms')}}
                </template>
                </v-radio>

                <v-radio  dense value="E" class="miradio ml-2" @change="compruebaForm">
                <template slot="label">
                    <img :src="require('@/assets/email.jpg')" class="mr-2">
                    {{$t('siempreavisarporemail')}}
                </template>
                </v-radio>     

                <v-radio v-if="hay_whatsapp" dense value="W" class="miradio ml-2" @change="compruebaForm">               
                <template slot="label">
                    <img :src="require('@/assets/whatsapp.png')" class="mr-2" >
                    {{$t('siempreavisarporwhatsapp')}}
                </template>
                </v-radio>

                <v-radio  dense value="N" class="miradio ml-2" @change="compruebaForm">
                <template slot="label">
                    <v-icon color="red" class="mr-2">do_not_disturb</v-icon>
                    {{$t('noavisarsiempre')}}
                </template>
                </v-radio>                 
            </v-radio-group>   
            <v-checkbox v-if="autoriza=='S'" v-model="doble" :label="$t('avisodoblepersona')" class="miradio mt-0 ml-2" @change="compruebaForm"/>

          
            </v-form>
            </v-card-text>
            <v-card-actions  class="card-actions">
                <v-btn @click="grabar" :disabled="!formValid"><v-icon color="primary" class="ml-4">save</v-icon>{{$t('grabar')}}</v-btn>
                <v-btn @click="cerrar"><v-icon color="red" class="ml-4">arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
            </v-card-actions>

        </v-card>
   </v-dialog>
</div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import ControlTexto from '@/components/ControlTexto.vue';
import {Persona, call_url, getParametro} from '@/library/misFunciones.js';
 
export default {
    name: 'ControlAviso',   
    components: {ControlTexto},
    data() {
        return {
            cargando:false,
            visible: false,
            persona: Persona,
            autoriza: 'N',
            siempre: 'N',
            doble: false,
            formValid: false,
   
        }
    },

    computed:  {
        ...mapState(['sesion']),
        hay_whatsapp() {
            return (getParametro("whatsapp")==="1");
        },

        // texto_autorizacion() {
        //     return "texto autorizacionnnnnnnj"
        // }
    },

    props: {
        label: String,
        value: String,
        info: String,
        colorInfo: String,
    },
    watch: {
    },        
    methods: {
        ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError','mostrarMensaje']),

        editar(persona){      
            //console.log(persona)
            //this.persona = persona;  
            this.persona = persona;
            if (persona.autorizacionAviso!=="" && persona.autorizacionAviso!=="N") {
              this.autoriza ="S";
              if (persona.autorizacionAviso.substring(0,1)==="S") 
                this.siempre = "S";
              else if (persona.autorizacionAviso.substring(0,1)==="E")
                this.siempre = "E";
              else if (persona.autorizacionAviso.substring(0,1)==="W")
                this.siempre = "W";
              else 
                 this.siempre = "N"
              this.doble =(persona.autorizacionAviso.substring(1,2)==="2")
            }
            else {
              this.autoriza ="N";  
              this.siempre ="N";
              this.doble = false;
            }         
            this.visible = true;
           
        },
        async grabar() {
           
          try{
            let valor;
            if (this.autoriza === "N") valor ="N";
            else {
                if (this.siempre === "S") valor ="S";
                else if (this.siempre === "E") valor ="E";
                else if (this.siempre === "W") valor ="W";
                else valor ="A";
                if (this.doble) valor +="2";
            }

            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            const data = await call_url("/agendab/grabaAutorizacion.php",{cod:this.persona.cod,autorizacionAviso:valor});
            // console.log("grabo autorizacion")
            if (data.resul==="OK") {
                this.mostrarMensaje(this.$i18n.t('autorizaciongrabadacorrectamente'));
                this.persona.autorizacionAviso = valor;
                // console.log("autorizacion grabada")

                this.sesion.ultima_lectura_personas = 0; //Para que se recargue la lista de personas
                this.$emit('input',valor);
                this.cerrar()
            }
            else this.mostrarError(data.mensaje);
          }
          catch(error) {
            console.log(error);
            this.mostrarError(error.response.data);
          }
          finally {
            this.ocultarLoading();
          }

        },
        abrir() {
            this.cargando = true;
            this.visible = true;
            this.cargando = false;
            this.formValid = false
        },
        cerrar(){
            this.visible = false;
            this.formValid = false
        },
        compruebaForm(){
            this.formValid = false
            setTimeout(this.compruebaForm2,5);
        },
        compruebaForm2(){
            this.formValid = true;          
        },
        autorizacion(){

        }
       
       
    },

}

</script>
<style scoped>
body {
    font-size: 8px;
}
.col {
    font-size: 12px !important;

}
.miradio >>> label {
    font-size: 12px !important;   
  }
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>