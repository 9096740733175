<template>
<div>
    <table border="0"><tr>
    <td>
        <v-text-field v-show="!tiene_foco" ref='pais' v-model="pais" :label="$t('NIF')" height="13" required :disabled="false"
        @focus="pais_onFocus" style="width:30px; border: 0px solid gray;" @input="change"/>

        <v-autocomplete v-show="tiene_foco" v-model="pais_combo" ref="pais_combo" :label="$t('NIF')" :disabled="disabled" :items="paises" return-object item-text="nombre" 
         @blur="pais_combo_blur"  @focus="pais_combo_onFocus" @input="pais_combo_change" dense style="margin-top:0px;" />

    </td>
    <td valign="top">
        <v-text-field ref='nif' v-model="nif" :rules="rules"  height="13" required :disabled="false" style="margin-left:-2px;" @input="change"/>
    </td>
    </tr>
    </table>
</div>
</template>

<script>


import {getParametro} from '@/library/misFunciones.js';
import paisesjson from  '@/assets/paises.json';


export default {
    name: 'ControlNif',   
    data() {
        return {
            cargando:false,
            nif:'',
            niftemp:'',
            pais: '',
            pais_combo:null,//{cod:'ES',nombre:'ES-España'},
            mensaje_error: '',
            paises: [], //[{cod:'ES',nombre:'ES-España'},{cod:'IT',nombre:'IT-Italia'}],
            tiene_foco: false,
            rules: [],        
        }
    },
    computed: {
      
    },
    props: {
        value: String,
        obligatorio: {type: Boolean, required: false, default: false},       
        disabled: Boolean,
      
    },
    watch: {
  
      value: { immediate: true, handler(newval, oldval) {
                //console.log("value cambiado:"+newval)
                if (newval) {
                    this.pais = newval.substring(0,2);
                    this.nif = newval.substring(2);
                }
                else {
                    this.pais = getParametro('paisNif')
                    this.nif = "";
                }
         }, 
      }, 
      pais: { immediate: true, handler(newval) {
                if (newval){
                    for (let i=0;i<this.paises.length;i++) {
                        if (this.paises[i].cod===newval) {
                            this.pais_combo = this.paises[i];
                            break;
                        }
                    }
                }
                else {
                    this.pais_combo = null;
                }
                this.change();
         }, 

      }       
    },    
    methods: {
        change(){
            // console.log("change")
            let valor="";
            //if (!this.hay_error() && this.nif!=="") valor = this.pais+this.nif;
            //else valor = "";
            this.get_error_nif();
            if (this.nif!=="") valor = this.pais+this.nif;
            else valor = "";
            //console.log("emito change: "+valor)
            this.$emit('input',valor);
        },
      
        // get_error(){
        //     let mensaje="";
        //     if (this.obligatorio && !this.pais) {
        //         mensaje = this.$i18n.t("valorobligatorio");
        //     }
        //     else if (this.obligatorio && this.nif==="") {
        //         mensaje = this.$i18n.t("valorobligatorio");
        //     }
        //     else if (this.nif!=="" && this.hay_error_nif())
        //         mensaje = this.get_error_nif();
        //     else mensaje = '';
        //     return mensaje;
        // },
        // hay_error() {
        //     if (this.get_error()) return true;
        //     else return false;
        // },
        get_error_nif() {
            
            if (this.nif==="") {
                if (!this.obligatorio) return true;
                else return this.$i18n.t("valorobligatorio");
            } 
            if (this.pais==="ES") {
                // let exp_nif = new RegExp('(^([X-Z]){1}([0-9]{7})([A-Z]{1}))|(^([0-9]{8})([A-Z])$)|(^([ABCDEFGHJKLMNPQRSUVW])([0-9]{7})([0-9A-J])$)',"g");
                let exp_nif = new RegExp('(^([0-9]{8})([A-Z])$)');
                let exp_nie = new RegExp('(^([X-Z]{1})([0-9]{7})([A-Z]{1})$)');
                let exp_cif = new RegExp('(^([ABCDEFGHJKLMNPQRSUVW])([0-9]{7})([0-9A-J])$)');

                if (this.nif.substring(0,1)==="X"||this.nif.substring(0,1)==="Y"||this.nif.substring(0,1)==="Z") {
                    if (!exp_nie.test(this.nif)) return this.$i18n.t("formatonieincorrecto");

                    let nie_prefix = this.nif.charAt(0);
                    switch (nie_prefix) {
                        case 'X': nie_prefix = 0; break;
                        case 'Y': nie_prefix = 1; break;
                        case 'Z': nie_prefix = 2; break;
                    }
                    if (!this.check_letra_nif( nie_prefix + this.nif.substr(1))) return this.$i18n.t("letranieincorrecto");
                }

                else if (this.nif.substring(0,1)>="0" && this.nif.substring(0,1)<="9") {
                    if (!exp_nif.test(this.nif)) return this.$i18n.t("formatonifincorrecto");
                    if (!this.check_letra_nif(this.nif)) return this.$i18n.t("letranifincorrecto");
                }

                else {
                  if (!exp_cif.test(this.nif)) return this.$i18n.t("formatocifincorrecto");
                  if (!this.check_letra_cif(this.nif)) return this.$i18n.t("letracifincorrecto");
                }

                return true;
            }
            else {
                if (this.nif.length<5) return this.$i18n.t("formatonifincorrecto");
                return true
            }
            

            // let exp_nif = new RegExp('(^([X-Z]){1}([0-9]{7})([A-Z]{1}))|(^([0-9]{8})([A-Z]))',"g");
            // console.log(this.nif);
            // console.log(exp_nif.test(this.nif));
            // return this.$i18n.t("valorobligatorio");
        },
        check_letra_nif(nif) {
            const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
            const letter = dni_letters.charAt( parseInt(nif, 10) % 23 );
            // console.log(letter)
            return (letter === nif.charAt(8))
        },
        check_letra_cif(cif) {
            let letter  = cif.substring(0,1);
            let number  = cif.substring(1,8);
            let control = cif.substring(8);

            let even_sum = 0;
            let odd_sum = 0;
            let n;
            for ( let i = 0; i < number.length; i++) {
                n = parseInt( number[i], 10 );
                // Odd positions (Even index equals to odd position. i=0 equals first position)
                if ( i % 2 === 0 ) {
                    // Odd positions are multiplied first.
                    n *= 2;
                    // If the multiplication is bigger than 10 we need to adjust
                    odd_sum += n < 10 ? n : n - 9;
                // Even positions
                // Just sum them
                } else {
                    even_sum += n;
                }
            }
            let control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
            let control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
            // Control must be a digit
            if ( letter.match( /[ABEH]/ ) ) {
                return control == control_digit;
            // Control must be a letter
            } else if ( letter.match( /[KPQS]/ ) ) {
                return control == control_letter;
            // Can be either
            } else {
                return control == control_digit || control == control_letter;
            }
        },


        // hay_error_nif() {
        //     if (this.get_error_nif()) return true;
        //     else return false;
        // },
        pais_combo_onFocus() {
            setTimeout(this.pais_combo_onFocus2,300);
        },
        pais_combo_onFocus2() {
            this.$refs.pais_combo.isMenuActive = true;
        },
        pais_onFocus() {
            this.tiene_foco=true; 
            this.set_comboPais(this.pais);
            setTimeout(this.pais_onFocus2,100);
        },        
        pais_onFocus2() {
            this.$refs.pais_combo.focus();
        },
        pais_combo_change() {
            this.$refs.nif.focus();
            this.pais = this.pais_combo.cod;
            //Para se reevalue el nif al cambiar de pais
            this.niftemp = this.nif;            
            this.nif += " ";
            setTimeout(this.pais_combo_change2,100);            
        },
        pais_combo_change2() { //Para se reevalue el nif al cambiar de pais
            this.nif = this.niftemp;
        },

        pais_combo_blur(){
            this.tiene_foco=false;

        },
        set_comboPais(newval){
            for (let i=0;i<this.paises.length;i++) {
                if (this.paises[i].cod===newval) {
                    this.pais_combo = this.paises[i];
                    break;
                }
            }

        }

       
    },

    mounted(){
      this.paises=paisesjson
        this.rules= [this.get_error_nif]
    }

}

</script>

