<template>
  <div>  
    <br/> 
    <PersonaEdit ref="personaEdit" @input="consulta"/>
    <PersonaCitas ref="personaCitas"/>


    <v-row><v-col sm="6" md="4">
      <v-text-field v-model="search" v-if="num_filas>5" :label="$t('buscarpersona')" append-icon="search" @input="consulta" outlined></v-text-field></v-col>
    </v-row>
    <v-card outlined elevation="1">                  
    <v-data-table  v-if="num_filas>0" :headers="cabecera" :items="sesion.personas" :search="search" :custom-filter="buscaTabla" 
            :hide-default-footer="filas_mostradas<=10">

        <template v-slot:item="row">
            <tr>
              <td>{{row.item.nombre}} {{row.item.apellidos}}
                <div v-if="row.item.telefono" class="d-block d-sm-none"> {{$i18n.t('telefono')}}: {{row.item.telefono}}</div>
                <div v-if="row.item.email" class="d-block d-md-none"> {{$i18n.t('email')}}: {{row.item.email.replace(',',', ')}}</div>
              </td>
              <td class="d-none d-sm-table-cell">{{row.item.telefono}}</td>
              <td class="d-none d-md-table-cell">{{row.item.email}}</td>
              <td>
                  <v-btn class="mx-2" fab dark small color="primary" @click="editar(row.item.cod)">
                      <v-icon dark>edit</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" fab dark small color="primary" @click="verCitas(row.item.cod,row.item.nombre,row.item.apellidos)">
                      <v-icon dark>mdi-calendar-month</v-icon>
                  </v-btn>
              </td>            
            </tr>
        </template>
      </v-data-table>
    <div v-if="num_filas==0"><h1>{{$i18n.t('NOPERSONAS')}}</h1></div>
    </v-card>
  
</div>
</template>



<script>

import {mapState, mapMutations} from 'vuex';
import {getPersonas} from '@/library/misFunciones.js';
import PersonaEdit from '@/components/PersonaEdit.vue';
import PersonaCitas from '@/components/PersonaCitas.vue';


export default {
  name: 'Persona',
  components: { PersonaEdit, PersonaCitas },
  data() {
    return {
        cabecera: [
            { text: this.$i18n.t('nombreapellidos'), value: 'nombre' },
            { text: this.$i18n.t('telefono'), value: 'telefono',class:'d-none d-sm-table-cell'},
            { text: this.$i18n.t('email'), value: 'email',class:'d-none d-md-table-cell'},
            { text: this.$i18n.t('editar'), value: 'editar',sortable: false }],
        // datos: [],
        search: '',
        filas_mostradas:11,
        // num_filas: -1
  }},
  computed: {
    ...mapState(['sesion']),
    nueva_persona() {return this.sesion.nueva_persona},
    num_filas() {return this.sesion.personas.length}
  },
  methods: {
    getPersonas,
    ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError']),
    consulta() {
      if (this.search.length===0) this.filas_mostradas = 11;
      else this.filas_mostradas = 0;
      this.getPersonas(true);
    },
    buscaTabla(value, search, item) {
      //console.log(item)
      if ((item.nombre+' '+item.apellidos).toUpperCase().indexOf(search.toUpperCase())>=0) {
        this.filas_mostradas ++;
        return true;
      }
      else return false;
    },
    nuevo(agendaDef) {
      this.$refs.personaEdit.nuevo(agendaDef);
    },
    editar(id) {
      this.$refs.personaEdit.editar(id);
    },
    // eliminar(id) {
    //   this.$refs.personaEdit.editar(id,true);
    // }  
    verCitas(cod,nombre,apellidos) {
      this.$refs.personaCitas.abrir(cod,nombre,apellidos);
    }
  },
  mounted(){
    this.sesion.rutaActual = "persona";
    this.consulta()
  },
  watch: {
    nueva_persona(val) {
        if (val) this.nuevo('');
        this.sesion.nueva_persona = false;
    },
  },
   
 
}
</script>

<style scoped>


  .novisiblemovil {
    display: none;
    visibility: hidden;
  }


</style>