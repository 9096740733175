<template>
  <v-row justify="center">
    <v-dialog persistent max-width="500" height="500" v-model="visible">
      <v-card>
          
        <v-card-title class="cabeceraModal">
            {{label}}
          <!-- <v-row>
            <v-col v-if="es_insert" cols="2"><v-icon left large>person_add</v-icon></v-col>
            <v-col v-if="es_insert">{{$t('introduzcanuevacita')}}</v-col>
            <v-col v-if="es_update" cols="2"><v-icon left large>assignment_ind</v-icon></v-col>
            <v-col v-if="es_update">{{$t('edicioncita')}}</v-col>
          </v-row> -->
        </v-card-title>
          
        <v-card-text class="mb-5">
           <p v-html="cuerpo_parsed"></p>
        </v-card-text>
        <v-card-actions>
             <v-btn :color="color_aceptar" @click="aceptar"><v-icon class="mr-4">{{icon_aceptar}}</v-icon>{{label_aceptar}}</v-btn>
             <!-- <v-btn color="gray" @click="cancelar" class="ml-8"><v-icon class="mr-4">cancel</v-icon>{{$t('cancelar')}}</v-btn> -->
             <v-btn color="gray" @click="cancelar" class="ml-8"><v-icon color="red" class="mr-4" small>arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

// import {mapState, mapMutations, mapGetters } from 'vuex';
// import {getServidor, getParametro, to_number, is_numeric, tienePermiso,char_to_date,date_to_char} from '@/library/misFunciones.js';

export default {
    name: 'Confirm',   

    props: {
        label: String,
        cuerpo: {type: String, required: false, default: '' },
        label_aceptar: String,
        icon_aceptar: String,
        color_aceptar: String
    },

    data() {
        return {
            visible: false,
        }
    },
    computed: {
        cuerpo_parsed() {
          // console.log("cuerpo:"+this.cuerpo)
          return this.cuerpo.replaceAll("\n","<br>");
        }
    },
    methods: {
        abrir() {
            this.visible = true;
        },
        aceptar(){
            this.$emit('confirm');
            this.visible = false;
        },        
        cancelar(){
            this.visible = false;
        }

    },


}

</script>
