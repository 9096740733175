

<template>
    <v-row v-if="(sesion.mostrar_anuladas || hora.estado!=='A') && (sesion.mostrar_noacude || hora.estado!=='N')" class="rowHora " :style="estilo_hora" @click="$emit('click',$event,hora)" >
        <v-col cols="1" class="colHora ma-0 pa-0">{{date_to_char(hora.inicio,"hh:mi")}}</v-col>
        <v-col cols="1" class="ma-0 pa-1"><v-img :src="getImagen_tipocita(hora.tipo_cita,hora.estado)" height="17" width="17" /></v-col>
        <v-col cols="1" class="ma-0 pa-1"><v-img :src="getImagen_opcion(hora.tipo_cita,hora.pagado,hora.rgpd,hora.urgencia,sesion.opcion)" height="17" width="17" /></v-col>
        <v-col cols="8" class="ma-0 pa-1 nooverflow">{{descripcion}}</v-col>
        <v-col cols="1" class="ma-0 pa-1"><v-img :src="getImagen_aviso(hora.estado_aviso)" min-width="17" max-height="17" max-width="15" /></v-col>
    </v-row>
</template>

<script>

import {mapState} from 'vuex';
import {date_to_char, getImagen_tipocita, getImagen_opcion, getImagen_aviso, existe_marca} from '@/library/misFunciones.js';


export default {
    name: 'Hora',   
    computed: {
        ...mapState(['sesion']),
        sm()  { if (this.num_dias>=2) return 2; },
        md()  { if (this.num_dias>=3) return 2; },
        lg()  { if (this.num_dias>=4) return 3; },

        descripcion() {
            // console.log(this.hora)
            let valor;
             if (this.hora.m_col && this.hora.m_col.length>=3) valor = this.hora.m_col[0]+ ' '+this.hora.m_col[1]+this.hora.descripcion;
             else valor = this.hora.descripcion;
             
             if (this.sesion.mostrar_mayusculas) valor = valor.toUpperCase();
             return valor;
        },
        estilo_hora(){
            let borde="";
            //if (this.hora.cod) borde="border-style: dotted; border:1px;"; //borde="border:1px dotted #d0d0d0;";
            if (this.hora.cod) borde="border: 1px dotted gray;"; //borde="border:1px dotted #d0d0d0;";
            // console.log(borde)
            
            let height = 0;
            if (this.num_horas>0) height = (this.alturaDia-40)/this.num_horas;
          
            let color_marca = existe_marca(this.marcas,this.hora.inicio,this.hora.fin) ;
            if (color_marca==="") color_marca = null;
            // let border = "";
            // if (this.hora.cod) border="border-style: dotted;"


            return "height:"+height+"px; background:"+this.color_fila(this.hora.color_agenda,color_marca)+"; color:#"+this.hora.color_cita+";"+borde  ;



        }

    },
    data() {
        return {
        }
    },
    props:{
        hora: {},
        //inicio: String,
        //fin: String ,
        alturaDia: Number,
        num_horas: Number,
        num_dias: Number,
        num_fila: Number,
        marcas: Array,
    },
    methods: {
        date_to_char,
        getImagen_tipocita,
        getImagen_opcion,
        getImagen_aviso,
        color_fila(color_agenda,color_marca) {
            if (color_agenda) { 
                if (color_marca) return 'linear-gradient(to right, #'+color_agenda+' 50%, #'+color_marca+' 100%)';
                else return '#'+color_agenda;
            }
            else if (color_marca) {
                return '#'+color_marca;
            }
            if (this.hora.indice % 2) return '#d7d7d7';
            else return '#ffffff';
        },
    },

}

</script>

<style scoped>
div{
    font-size: 11px;
    overflow: hidden;

}
.rowHora {
    font-size: 10px;
}
.colHora {
    font-size: 11px;
    color: #505050;
    margin-left:-10px;
    margin-top:-15px;
    overflow: visible;
}

</style>
