<template>
<div style="border:0px solid black; height:30px">
    <div class="divTitle" :style="{'color':color,'visibility':title_visibility}">{{label}}</div>
    <div class="divContenido">
        <input v-if="tipo!=='textarea'" :type="tipo" v-model="value_data" @change="change" @focus="onfocus" @blur="onblur" :class="{'cajaTexto':true,'campoError':hay_error}" :disabled="disabled" :placeholder="placeholder" @keyup="keypress" :size="size" :maxlength="maxlength"
            :style="style" />
        <textarea v-if="tipo==='textarea'" v-model="value_data" @change="change" @focus="onfocus" @blur="onblur" :class="{'cajaTexto':true,'campoError':hay_error}" :disabled="disabled" :placeholder="placeholder" @keyup="keypress" :size="size" :maxlength="maxlength"
            :style="style" />    
    </div>
</div>
</template>

<script>

import {esNumeroMovil,esEmail,is_numeric} from '@/library/misFunciones.js';

export default {
    name: 'ControlTexto',   
    data() {
        return {
            cargando:false,
            value_data:'',
            mensaje_error: '',
            foco: false,

           
        }
    },
    computed: {
        tipo() {
            if (this.type==="number") return "text";
            else return this.type;
        },
        hay_error(){
            if (this.mensaje_error) return true;
            else return false
        },
        style() {
            let resul=''
            if (this.font_size) resul+= "font-size:"+this.font_size+"px; ";
            if (this.height) resul+= "height:"+this.height+"px; ";
            if (this.width) resul+= "width:"+this.width+"px; ";
            resul+= "padding-bottom:2px; ";
            // if (this.color) resul+= "color:"+this.color;
            return resul
        },
        color() {
            if (this.foco) return "rgb(80, 80, 250)";
        },
        title_visibility() {
            // return 'hidden'
            if (this.label) return 'visible';
            else return 'hidden';
        }

    },
    props: {
        label: String,
        value: String,
        size: {type: Number, required: false, default: 25},
        maxlength: {type: String, required: false, default: '200'},
        height: {type: Number, required: false, default: 30},
        width: {type: String, required: false, default: ''},
        // color: {type: String, required: false, default: ''},
        font_size: {type: String, required: false, default: '12'},
        placeholder: String,
        obligatorio: {type: Boolean, required: false, default: false},
        type: {type: String, required: false, default: 'text'},
        entero: {type: Boolean, required:false, default: false}, //Solo se usa para type=number
        positivo: {type: Boolean, required:false, default: false},//Solo se usa para type=number
        mensaje_when_error: {type: String, required: false, default: ''},
        disabled: Boolean,
      
    },
    watch: {
  
      value: { immediate: true, handler(newval, oldval) {
                this.value_data = newval;
         }, 
      },        
    },    
    methods: {
        change(){
            //console.log()
        },
        keypress(){
            this.getError();
            this.$emit('input',this.value_data)
            this.$emit('change',this.value_data)
        },
      
        getError(){
            if (this.obligatorio && this.value_data==="") {
                this.mensaje_error = this.$i18n.t("valorobligatorio");
            }
            //console.log(esNumeroMovil(this.value_data));
            else if (this.type==="email" && this.value_data!=="" && !esEmail(this.value_data) ) {
                if (this.mensaje_when_error) 
                    this.mensaje_error = this.mensaje_when_error;
                else
                    this.mensaje_error = this.$i18n.t("emailintroducidoincorrecto");
                //return false;
            }
            else if (this.type==="movil" && this.value_data!=="" && !esNumeroMovil(this.value_data) ) {
                if (this.mensaje_when_error) 
                    this.mensaje_error = this.mensaje_when_error;
                else
                    this.mensaje_error = this.$i18n.t("numeromovilintroducidoincorrecto");
                // return false;
            }
            else if (this.type==="number" && this.value_data!=="" && !is_numeric(this.value_data,this.positivo,this.entero) ) {
                if (this.mensaje_when_error) 
                    this.mensaje_error = this.mensaje_when_error;
                else
                    this.mensaje_error = this.$i18n.t("numerointroducidoincorrecto");
                
                // return false;
            }
            else this.mensaje_error = '';
            return this.mensaje_error 
        },
        onblur() {
            //this.isValid();
            this.foco = false;
        },
        onfocus() {
            //this.isValid();
            this.foco = true;
        }
       
       
    },
      

}

</script>

<style>
 .cajaTexto {
  border: 1px solid gray;
  border-radius: 4px;
  padding-top : 6px;
  padding-bottom: 6px;
  padding-left: 3px;
  color: rgb(30,30,30); 
 }
  .cajaTexto:focus {
    border-color:darkblue;
    border: 1px solid gray;
    outline: 1px solid rgb(70,70,250);    

 } 
 .campoError {
     background-color: red;    
 }
 .divTitle {
   /* border:1px solid black;  */
   position: relative;
   left: 7px;
   top: -12px; 
   height: 15px;
   z-index: 1;
   font-size: 11px;
   background-color:white; 
   display: inline-block;
   padding-left: 3px;
   padding-right: 3px;

   /* text-shadow: 0 0 0px white; */
 }
 .divContenido {
   position: relative;
   left: 0px;
   top: -24px; 
 }


  
</style>
