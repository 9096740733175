<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view v-if="sesion.existe && !sesion.mostrar_empresa" />
        <Dashboard @opcionesClick="opcionesClick" />
        <Login/>
        <Logout/>
        <EmpresaSelec/>
        <Opciones ref="opciones"/>
        <Mensaje/>
        <Cargando/>      
      </v-container>
    </v-main>
  </v-app>
</template>



<script>
 import Cargando from '@/components/Cargando.vue';
 import Mensaje from '@/components/Mensaje.vue';
 import Dashboard from '@/components/Dashboard.vue';
 import EmpresaSelec from '@/components/EmpresaSelec.vue';
 import UsuarioSelec from '@/components/UsuarioSelec.vue';
 import Login from '@/components/Login.vue';
 import Logout from '@/components/Logout.vue';
 import Opciones from '@/components/Opciones.vue';
 import {mapMutations, mapState} from 'vuex';
import {date_to_char} from '@/library/misFunciones.js';

export default {
  name: 'App',
  components: {Cargando,Mensaje,Dashboard,EmpresaSelec,UsuarioSelec,Login,Logout,Opciones} ,
  computed: {
    ...mapState(['sesion']),
  },
  methods: {
    ...mapMutations(['iniciaSesion','cerrarSesion','setFecha','atras_fecha','adelante_fecha']),
    opcionesClick(){
      this.$refs.opciones.show();
    },

    teclaPulsada(event) {
      //El evento se dispara 2 o mas veces
      // const now = (new Date()).getTime();
      // console.log("tiempo",now,this.teclaPulsadaTime,now-this.teclaPulsadaTime)
      // if (now-this.teclaPulsadaTime<5) return;
      // this.teclaPulsadaTime = now;
      if (this.sesion.rutaActual!=='agenda') return;
      if (this.sesion.citaEdit_visible) return;
      if (this.sesion.opciones_visible) return;

      //  console.log(event.key)
      if (event.key == 'ArrowUp' || event.key == 'PageUp') {
        // console.log("atras fecha")
        this.atras_fecha();
        event.preventDefault();
      }
      else if (event.key == 'ArrowDown' || event.key == 'PageDown') {
        // console.log("adelante fecha")
        this.adelante_fecha();
        event.preventDefault();
      }
    },



  },
  beforeMount() { 
    // console.log("beforemounte",this.sesion.ancho_pantalla,window.innerWidth)

  },
  mounted(){
    //tengo que asignar antes porque necesito que esté asignado cuando se ejecuta iniciaSesion
    this.sesion.ancho_pantalla = window.innerWidth;
    if (localStorage.sesion) {
      // console.log(localStorage.sesion)
      this.iniciaSesion(JSON.parse(localStorage.sesion));
    }
    else this.cerrarSesion(false);
    this.sesion.fecha=date_to_char(new Date(),"yyyy-mm-dd");
    this.sesion.ancho_pantalla = window.innerWidth;
    // console.log("mounte",this.sesion.ancho_pantalla,window.innerWidth)
    window.addEventListener('keydown', this.teclaPulsada);
  },    
};



</script>
