<template>
 <div v-if="sesion.existe && !sesion.mostrar_empresa">
 <Marcadores ref="marcadores" />  
        <UsuarioSelec ref="usuarioSelec"/>
 <v-app-bar app color="#d0d0d0"  height="50" outlined>

  
  <v-row v-if="sesion.rutaActual=='agenda'">
    <table border="0" cellpadding="0" cellspacing="0"><tr>
      <td><ControlBoton label="" :image="require('@/assets/menu.png')" @click="showDrawer" :margin_top="-8" :tiene_borde="false" :width="30"></ControlBoton></td>
      <td  width="10"/>
      <td><ControlBoton :image="require('@/assets/anterior.gif')" :margin_top="-8" :tiene_borde="false" :width="35" @click="atras_fecha" />     </td>
      <td><ControlFecha v-model="sesion.fecha" :rules="[rules.fecha]" :clearable="false" /> </td>
      <td><ControlBoton :image="require('@/assets/siguiente.gif')" :margin_top="-8" :tiene_borde="false" :width="35" @click="adelante_fecha" />     </td>
      <td width="5"/>
      <td><v-autocomplete v-model="sesion.agenda" :items="sesion.agendas" @change="agendaChange" return-object item-text="nombre" :placeholder="$t('seleccioneagenda')" 
                           class="autocompleto" required outlined  dense :no-data-text="$t('noexisteningunaagenda')" :style="width='100px'"/>
      </td>
      <td v-if="sesion.marcador" width="2"/> 
      <td v-if="sesion.marcador"><ControlBoton :label="sesion.marcador.nombre" :color_texto="sesion.marcador.color" :image="require('@/assets/paleta.png')" :margin_top="-6" @click="abrirMarcadores()"></ControlBoton>     </td>

    </tr></table>
  </v-row>


   <v-row v-if="sesion.rutaActual=='persona'">

    <table border="0" cellpadding="0" cellspacing="0"><tr>
      <td><ControlBoton label="" :image="require('@/assets/menu.png')" @click="showDrawer" :margin_top="-8" :tiene_borde="false" :width="30"></ControlBoton></td>
      <td width="5"/>
      <td><v-icon large >people</v-icon></td>
      <td width="5"/>
      <td><h2>{{$t('personas')}}</h2> </td>
      <td width="15"/>
      <td><v-btn class="mx-2" @click="sesion.nueva_persona=true" color="primary" ><v-icon left>person_add</v-icon>{{$t('nuevapersona')}}</v-btn></td>

    </tr></table>


      <!-- <v-col cols="1"><v-app-bar-nav-icon @click.stop="showDrawer"></v-app-bar-nav-icon></v-col>
      <v-col cols="6" md="6" lg="6"><v-row><v-col class="text-right"><v-icon large >people</v-icon></v-col><v-col ><h2>{{$t('personas')}}</h2> </v-col></v-row></v-col>
      <v-col cols="5"><v-btn class="mx-2" @click="sesion.nueva_persona=true" color="primary" ><v-icon left>person_add</v-icon>{{$t('nuevapersona')}}</v-btn></v-col>   -->
   </v-row>

    </v-app-bar>

    <v-navigation-drawer  color="#d0d0d0" v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="opcionDrawer" >
          <div v-if="sesion.rutaActual=='agenda'">
          <!-- <v-btn-toggle  v-model="sesion.vista" @change="modoChange"  mandatory color="orange" class="botonera">
                  <v-btn value="nueva"> <v-img :src="require('@/assets/anchoTotal.png')" height="40" /> </v-btn>
                  <v-btn value="columna" v-if="sesion.varias_agendas"> <v-img :src="require('@/assets/columnas.png')" height="40" /> </v-btn>
                  <v-btn value="clasica" v-if="!sesion.varias_agendas && sesion.agenda && sesion.agenda.tipo!=='R'"> <v-img :src="require('@/assets/vistaClasica.png')" height="40" /> </v-btn>
          </v-btn-toggle> -->
          <v-btn-toggle  v-model="sesion.vista" @change="modoChange"  mandatory color="orange" class="botonera">
                  <v-btn value="nueva"> <v-img :src="require('@/assets/anchoTotal.png')" height="40" /> </v-btn>
                  <v-btn value="columna" v-show="sesion.varias_agendas"> <v-img :src="require('@/assets/columnas.png')" height="40" /> </v-btn>
                  <v-btn value="clasica" v-show="!sesion.varias_agendas && sesion.agenda && sesion.agenda.tipo!=='R'"> <v-img :src="require('@/assets/vistaClasica.png')" height="40" /> </v-btn>
          </v-btn-toggle>
          <br/><br/>
          <v-btn-toggle v-model="sesion.opcion"  mandatory color="orange" class="botonera" @change="drawer=false;">
                  <v-btn value="euro"> <v-img :src="require('@/assets/euroVerde2.png')" height="20" /> </v-btn>
                  <v-btn value="rgpd"> <v-img :src="require('@/assets/candadoRojo2.png')" height="25" /> </v-btn>
                  <v-btn value="urgencia"> <v-img :src="require('@/assets/alertaNaranja2.png')" height="20" /> </v-btn>
          </v-btn-toggle>

          <v-slider v-model="sesion.num_dias" :label="$t('numerodias')" @change="numDiasChange" thumb-color="red" thumb-label="always" min="1" max="7" dense thumb-size="17" class="slider"></v-slider>
          <v-divider></v-divider>
          </div>

          <v-list-item v-if="sesion.rutaActual!=='agenda'" link :to="{name:'agenda'}" :selectable="false"  >
            <v-list-item-title><v-icon class="mr-4">people</v-icon>{{$t('agenda')}}</v-list-item-title>
          </v-list-item>

          <v-list-item  v-if="sesion.rutaActual!=='persona' && tienePermiso('PER_GESTIONPACIENTES_CT')" link :to="{name:'persona'}"  >
            <v-list-item-title><v-icon class="mr-4">people</v-icon>{{$t('personas')}}</v-list-item-title>
          </v-list-item>

          <v-list-item  v-if="sesion.rutaActual=='agenda'" @click="abrirMarcadores()">
            <v-list-item-title><v-icon class="mr-4">palette</v-icon>{{$t('marcas')}} </v-list-item-title>
          </v-list-item>

          <v-list-item  v-if="sesion.rutaActual=='agenda'" @click="$emit('opcionesClick'); drawer=false; opcionDrawer=null;">
            <v-list-item-title><v-icon class="mr-4">tune</v-icon>{{$t('opciones')}}</v-list-item-title>
          </v-list-item>

          <v-list-item  @click="version_antigua">
            <v-list-item-title><v-icon class="mr-4">loop</v-icon>{{$t('versionantigua')}}</v-list-item-title>
          </v-list-item>


          <v-list-item v-if="sesion.num_empresas>1" @click="setEmpresa(null); drawer=false;">
            <v-list-item-title><v-icon class="mr-4">loop</v-icon>{{$t('cambiarempresa')}}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="sesion.nombre_usuario=='soporte@infonetsoftware.com'" @click="abrirUsuarioSelec();">
            <v-list-item-title><v-icon class="mr-4">loop</v-icon>{{$t('cambiarusuario')}}</v-list-item-title>
          </v-list-item>

          <v-list-item @click="cerrarSesion(true)"> 
            <v-list-item-title><v-icon class="mr-4">power_settings_new</v-icon>{{$t('cerrarsesion')}}</v-list-item-title>
            <!-- <v-list-item-content> Usuario:{{sesion.nombre_usuario}}</v-list-item-content> -->
            <!-- <v-list-item-subtitle>poeoe</v-list-item-subtitle> -->
            <!-- <v-list-item-action>{{sesion.nombre_usuario}}</v-list-item-action> -->
            <!-- <v-list-item-content>pppp</v-list-item-content> -->
          </v-list-item>
          Usuario: {{sesion.nombre_usuario}}
          <br>Empresa: {{sesion.nombreEmpresa}}

        </v-list-item-group>
      </v-list>
      <v-divider/>
      <v-icon class="mr-4">copyright</v-icon>Agenda móvil versión {{version}}
      
    </v-navigation-drawer>
</div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';
import ControlBoton from '@/components/ControlBoton.vue';
import ControlFecha from '@/components/ControlFecha.vue';
import Marcadores from '@/components/Marcadores.vue';
 import UsuarioSelec from '@/components/UsuarioSelec.vue';
import {tienePermiso} from '@/library/misFunciones.js';

export default {
  name: 'Dashboard',
  components: { ControlFecha, Marcadores, ControlBoton,UsuarioSelec },
  data: () => ({
    drawer: false,
    opcionDrawer: null,
    rules: {
        fecha: v => true || this.$i18n.t('fechaincorrecta'),
    },
  }),
  computed: {
    ...mapState(['sesion','version']),
    agenda() { return this.sesion.agenda },
    fecha() { return this.sesion.fecha},
    // ancho_separador(){
    //   if (sesion.ancho_pantalla<500) return 10;
    //   else if (sesion.ancho_pantalla<600) return 15;
    //   else if (sesion.ancho_pantalla<700) return 20;
    //   else if (sesion.ancho_pantalla<800) return 25;
    //   else return 30;
    // }
    
  },
  methods: {
    tienePermiso,
    ...mapMutations(['setEmpresa','asigna_vista_agenda','atras_fecha','adelante_fecha','cerrarSesion']),
    ...mapActions(['getCitasIntervalos']),
   
    agendaChange(){     
      // console.log("agendaChange llamo a getCitasIntervalos")
      this.asigna_vista_agenda()
      this.getCitasIntervalos(); 
    },
    modoChange(){
      this.drawer=false;
      if (this.sesion.agenda) {
        // console.log("modoChange almaceno el nuevo modo:"+this.sesion.vista)
        this.sesion.agenda_vista[this.sesion.agenda.cod] = this.sesion.vista;
        localStorage.sesion =JSON.stringify(this.sesion);
      }
      // console.log("modoChange llamo a getCitasIntervalos")
      this.getCitasIntervalos(); 
    },
    numDiasChange(){
      // console.log("numDiasChange llamo a getCitasIntervalos")
      this.getCitasIntervalos(); 
    },
    showDrawer() {
      window.scrollTo(0,0);
      this.drawer = !this.drawer;
    },
    abrirMarcadores() {
      this.drawer=false;
      this.$refs.marcadores.abrir();
    },
    abrirUsuarioSelec() {
      this.$refs.usuarioSelec.show();
      this.drawer=false;
    },
    version_antigua(){
      //window.location.href = 'https://www.grupoinfonet.es/agenda/agenda.php';      
      window.location.href = '../agenda/agenda.php';      
    }
  },

  watch: {
    group () {
      this.drawer = false
    },
    fecha (val) {
      // console.log("fecha cambiada: llamo a getCitasIntervalos")
      this.getCitasIntervalos();
    },
  },
}

  </script>

  <style scoped>
  /* .fecha {
    font-size: 14px !important;   
  } */
  .autocompleto{
    font-size: 14px !important;
    margin-top: 20px !important;
  }
  .slider {
    margin-top: 20px;
    margin-bottom: -10px;
  }
  .slider >>> label {
    font-size: 12px !important;   
  }
  .botonera {
    margin-top: -5px;
  }
  .barraBotones{
    width: 100%;
    height: 60px;
    overflow: hidden;        
  }
</style>