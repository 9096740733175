<template>
    <v-snackbar v-model="mensaje.estado" :color="mensaje.color" timeout="10000" right top>

        <v-icon v-if="!mensaje.isError" class="mr-2">done_outline</v-icon>
        <v-icon v-if="mensaje.isError" class="mr-2" red>warning</v-icon>
        {{ mensaje.texto }}  
        <template v-slot:action="{ attrs }">
            <v-btn color="blank" text icon v-bind="attrs" @click="mensaje.estado=false"><v-icon>close</v-icon></v-btn>
        </template>
    </v-snackbar>    
</template>


<script>

import {mapState} from 'vuex';

export default {
    name: 'Mensaje',
    computed: {
        ...mapState(['mensaje'])
    }

    
}
</script>
