<template>
  <v-row justify="center">
    <v-dialog persistent max-width="500" v-model="visible">
      <v-card>
        <v-card-title class="cabeceraModal">
          <v-row>
            <v-col v-if="es_insert" cols="2"><v-icon left large>person_add</v-icon></v-col>
            <v-col v-if="es_insert">{{$t('nuevapersona')}}</v-col>
            <v-col v-if="es_update" cols="2"><v-icon left large>assignment_ind</v-icon></v-col>
            <v-col v-if="es_update">{{$t('edicionpersona')}}</v-col>
            <!--<v-col v-if="es_delete">{{$t('eliminararchivador')}}</v-col>-->
          </v-row>
        </v-card-title>
          
        <v-card-text>
          <v-form v-model='formValid' ref='form' onsubmit='return false' >
            <v-row><v-col>
              <v-text-field ref='nombre' v-model="rec.nombre" :rules="[rules.min,rules.max(200)]" :label="$t('nombre')" height="13" dense required :disabled="false"/>
              </v-col>
            </v-row>
            <v-row><v-col>
              <v-text-field ref='apellidos' v-model="rec.apellidos" :rules="[rules.min,rules.max(35)]" :label="$t('apellidos')" height="13" dense required :disabled="false"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-if="sesion.es_medico()" ref='seudonimo' v-model="rec.seudonimo" :rules="[rules.minseudo,rules.max(30)]" :label="$t('seudonimo')" height="13" :disabled="false"  class="mayusculas"/>
              </v-col>
              <v-col cols="6">
                
                <ControlNif v-model="rec.nif"  />
              </v-col>
            </v-row>
            <v-row><v-col>
              <v-textarea ref='telefono' v-model="rec.telefono" rows="2" :rules="[rules.max(150)]" :label="$t('telefono')" dense :disabled="false" append-icon="phone"/>
              </v-col>
            </v-row>
            <v-row><v-col>
              <v-text-field ref='email' v-model="rec.email" :rules="[rules.max(250)]" :label="$t('email')" dense height="13" :disabled="false"/>
              </v-col>
            </v-row>
            <v-row><v-col>
              <v-autocomplete v-if="sesion.es_medico() && es_insert" v-model="rec.profesional" :items="profesionales" return-object item-text="nombre" :label="$t('profesional')" 
                           class="autocompleto" required  dense :no-data-text="$t('noexisteningunprofesional')"/>
            </v-col></v-row>
          </v-form>

        </v-card-text>
        <v-card-actions>
             <v-btn color="primary" @click="grabar" :disabled="!formValid"><v-icon class="mr-4">save</v-icon>{{$t('grabar')}}</v-btn>
             <!-- <v-btn color="error" @click="cerrar" class="ml-8"><v-icon class="mr-4">cancel</v-icon>{{$t('cancelar')}}</v-btn> -->
             <v-btn color="gray" @click="cerrar" class="ml-8"><v-icon color="red" class="mr-4" small>arrow_back_ios</v-icon>{{$t('cancelar')}}</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
//mport axios from "axios";
import {call_url, Persona} from '@/library/misFunciones.js';
import ControlNif from '@/components/ControlNif.vue';

export default {
    name: 'PersonaEdit',   
    components: { ControlNif},

    computed: {
        ...mapState(['sesion']),
        noVacio() {
            return true;
        }
    },

    props:{
      value: Persona
    },

    data() {
        return {
            visible: false,
            es_insert: false,
            es_update: false,
            //es_delete: false,
            rec: {},
            profesionales: [],
            selected: undefined,
            formValid: false,
            rules: {
                min: v => v.length >= 2 || this.$i18n.t('minimo2caracteres'),
                max: len => v => v.length <= len || this.$i18n.t('tamanomaximoexcedido'),
                minseudo: v => (v.length == 0 ||v.length >= 4) || this.$i18n.t('seudo4caracteres'),
            },

        }
    },
    methods: {
        ...mapMutations(['mostrarLoading','ocultarLoading','mostrarError','mostrarMensaje']),

        // nif_correcto(v){
        //   console.log("nif_correcto")

        // },
        async editar(cod){
          try{
            this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
            const data = await call_url("/agendab/getPersona.php",{"cod":cod});
            if (data.resul==="OK") {
                this.rec = data.persona;
                this.visible = true;
                this.es_insert = false;
                this.es_update = true
                setTimeout(this.foco,100);                
            }
            else this.mostrarError(data.mensaje);

          }
          catch(error) {
            console.log(error);
            this.mostrarError(error.messaje);
          }
          finally {
            this.ocultarLoading();
          }

        },

        foco() {
           this.$refs.nombre.focus();
           this.$refs.form.resetValidation();
           if (this.es_insert && this.selected) this.rec.profesional = this.selected;
           this.rec.profesional = null
        } ,
        async nuevo(agendaDef) {
          await this.getProfesionales(agendaDef,true)
          this.rec = {cod:'', nombre: '', apellidos: '',nif:'',seudonimo:'',telefono:'',email:'',profesional:''},
          this.visible = true;
          this.es_insert = true;
          this.es_update = false;
          setTimeout(this.foco,100);
        },
        async grabar(){
          try{
            this.mostrarLoading(this.$i18n.t('grabando')+'...');
            //const data =(await axios.post(getServidor()+"/agendab/grabaPersona.php",this.rec)).data;         
            const data = await call_url("/agendab/grabaPersona.php",this.rec);
            if (data.resul==="OK") {
              this.mostrarMensaje(this.$i18n.t('personagrabadacorrectamente'));
              this.sesion.ultima_lectura_personas = 0;
              this.$emit('input',new Persona(data.persona));
              //this.$emit('input',data.persona);
              //this.$emit('change')
              this.visible = false;
            }
            else this.mostrarError(data.mensaje);
          }
          catch(error) {
            console.log(error);
            this.mostrarError(error.response.data);
          }
          finally {
            this.ocultarLoading();
          }

        },
        cerrar(){
            this.visible = false;
        },
        async getProfesionales(agendaDef,soloActivos){
            try{
                if (this.sesion.es_medico()) {
                    this.mostrarLoading(this.$i18n.t('leyendodatos')+'...');
                    const data = await call_url("/agendab/getProfesionales.php",{'agendaDef':agendaDef});                    
                    if (data.resul==="OK") {
                        if (soloActivos) this.profesionales = data.profesionales.filter(objeto => objeto.fecha_baja === null)
                        else this.profesionales = data.profesionales;
                        this.selected = data.selected;
                    }
                    else this.mostrarError(data.mensaje);
                }
            }
            catch(error) {
                console.log(error);
                this.mostrarError(error.message);
            }
            finally {
                this.ocultarLoading();
            }

        }
    },


 

}

</script>

<style>


</style>
