<template>
<div>


<v-row><v-col>
      <!-- <v-text-field v-model="texto_value" :label="label" class="mt-3x fecha"  :rules="rules" @blur="fecha_texto_change()" @click="visible=true" @click:append="visible=true"
       :clearable="clearable" @click:clear="date_value = null" readonly :disabled="disabled" :append-icon="append_icon"></v-text-field> -->


         <!-- <button type="button" v-text="texto_value" :label="label" class="mt-3x fecha"  @click="visible=true" @click:append="visible=true"
         :disabled="disabled" :append-icon="append_icon"></button>    -->

 <v-btn :label="label" class="fecha"  @click="visible=true" @click:append="visible=true" variant="outlined"
         :disabled="disabled" :append-icon="append_icon" color="#d0d0d0" >
         <span class="fecha">{{texto_value}}
         </span><v-img src="@/assets/calendar.png" max-height="35" max-width="35" style="margin-left:-22px;"></v-img>
         </v-btn>
  
    
 

</v-col></v-row>

    <v-dialog max-width="300" v-model="visible">
       <v-date-picker v-model="date_value" label="etiqueta" first-day-of-week="1" @input="calendario_change" :weekday-format="nombreDiaSemana"></v-date-picker>
       <div class="div_date"><v-btn text color="blue" @click="ir_hoy">{{$i18n.t('irhoy')}}</v-btn></div>
   </v-dialog>
</div>
</template>

<script>

import MenuIcon from 'vue-material-design-icons/Menu.vue';
import {to_number, date_to_char, now, char_to_date} from '@/library/misFunciones.js';
 
export default {
    name: 'ControlFecha',   
    components: {
      MenuIcon,
    },
    data() {
        return {
            visible: false,
            texto_value: '',
            date_value: null,
            rules: []
        }
    },
    props: {
        label: String,
        value: String,
        disabled: Boolean,
        flechas: {type: Boolean, required: false, default: false },
        // avance_flecha: {type: Number, required: false, default: 7 },
        clearable: {type: Boolean, required: false, default: true },
        required: {type: Boolean, required: false, default: false },
        mostrar_icono: {type: Boolean, required: false, default: true }
    },
    computed: {
      append_icon(){
        //if (window.innerWidth>500) return "date_range";
        if (this.mostrar_icono) return "date_range";
        else return ""
      },
      ancho_cajatexto(){
        if (this.flechas) return 8;
        else return 12;
      }
        
      
    },
    watch: {
      // date_value (val) {  
      //   this.texto_value = this.date_to_string(val)
      //   this.$emit('input',val)
      // },
      value: { immediate: true, handler(newval, oldval) {
            if (!newval) this.date_value = null;
            else {
              if (typeof newval =="string") {
                this.date_value = newval.substring(0,10);
                this.texto_value = this.date_to_string(newval.substring(0,10))
              } 
              // if (typeof newval =="string" && newval.length>10) {
              //   this.date_value = newval.substring(0,10);
              //   //this.texto_value = this.date_to_string(newval)
              // } 
              // else if (typeof newval =="string" && newval.length===10) {
              //   this.date_value = newval;
              //   //this.texto_value = this.date_to_string(newval);
              // } 
            }
         }, 
      },        
    },    
    methods: {
        
        // cerrar(){
        //     this.visible = false;
        // },
        calendario_change(){
          const valorAnterior = this.texto_value;
          this.texto_value = this.date_to_string(this.date_value);
          this.visible = false;
          if (valorAnterior !== this.texto_value) {
            this.$emit('input',this.date_value)
            this.$emit('change',this.date_value)
          }
        },
        date_to_string(fecha) {
          if (!fecha) return null
          const [year, month, day] = fecha.split('-')
          return `${day}/${month}/${year}`          
        },
        // objetodate_to_string(fecha) {
        //   if (!fecha) return ''
        //   const day = fecha.getDate().toString()
        //   const month = (fecha.getMonth()+1).toString()
        //   const year = fecha.getFullYear().toString()
        //   return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        // },

        string_to_date(cad) {
          if (!cad) return null
          const [day, month, year] = cad.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },     
        fecha_texto_change() {
          if (!this.texto_value || this.formato_fecha_correcto()===true) {
            this.date_value = this.string_to_date(this.texto_value)
          }
        },
        formato_fecha_correcto() {
          const cad=this.texto_value
          try{
            if (!cad) {
              return !this.required
            }
            const [day, month, year] = cad.split('/')
            let wfecha=new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)
            if (wfecha.getDate()==to_number(day) && wfecha.getMonth()+1==to_number(month) && wfecha.getFullYear()==to_number(year)) {
              return true
            }
            else {
              return this.$i18n.t('formatoincorrecto')
            }
          }
          catch(error){
            return this.$i18n.t('formatoincorrecto');
          }
        },
        ir_hoy(){
          this.date_value = date_to_char(now(),"yyyy-mm-dd");
          this.calendario_change();
        },
        nombreDiaSemana(date){

          const WeekDays = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];
          return WeekDays[char_to_date(date).getDay()];
  
        }
        



    },
    mounted() {
        this.rules= [this.formato_fecha_correcto]
    }


 

}

</script>

<style>
 .fecha{
      width: 100px;
      font-size: 11px;
      margin-top:-10px;
      Xpadding-left: -20px;
      padding-right: 24px;
      padding-top: 10px;
      Xmargin-left:-10px;
      Xmargin-right:-10px;
      
}
.div_date {
  background-color: #ffffff;
  height: 40px;
  Xpadding-left: 200px;
  margin-top: -20px;
}
</style>
