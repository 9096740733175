<template>
<div>


    <v-row><v-col>
        <v-text-field v-model="duracion_texto" :label="label" class="texto"  @click="click" @click:append="visible=true" 
        :clearable="clearable" @click:clear="hora='';minuto='';" readonly :disabled="disabled" :append-icon="append_icon" :max-width="90"></v-text-field>
        </v-col>
    </v-row>
    <v-dialog max-width="500" v-model="visible">
        <v-card>
            <!-- <v-time-picker  v-model="controlHoraValue" format="24hr" full-width :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55]" />          -->
            <div class="titulo">{{duracion_previsualiza}}</div>
            <!-- <span style="margin-left:5px; font-size:14px;">Hora:</span><br/> -->
            <br/>
            <!-- <v-btn-toggle  v-model="hora" mandatory color="red" rounded > -->
                <v-row dense>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion>=1440-5" @click="duracion+=5"  color="green"> +5 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion>=1440-10" @click="duracion+=10" color="green"> +10 minutos </v-btn></v-col>
                    <v-col v-if="sesion.ancho_pantalla>450" cols="4" sm="3"><v-btn small :disabled="duracion>=1440-15" @click="duracion+=15" color="green"> +15 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion>=1440-30" @click="duracion+=30" color="green"> +30 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion>=1440-60" @click="duracion+=60" color="green"> +1 hora </v-btn></v-col>
                </v-row>
                <br/><br/>
                <v-row dense>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion<=5"  @click="duracion-=5"  color="red"> -5 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion<=10" @click="duracion-=10" color="red"> -10 minutos </v-btn></v-col>
                    <v-col v-if="sesion.ancho_pantalla>450" cols="6" sm="3"><v-btn small :disabled="duracion<=15" @click="duracion-=15" color="red"> -15 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion<=30" @click="duracion-=30" color="red"> -30 minutos </v-btn></v-col>
                    <v-col cols="6" sm="3"><v-btn small :disabled="duracion<=60" @click="duracion-=60" color="red"> -1 hora </v-btn></v-col>
                </v-row>
             
            <!-- </v-btn-toggle> -->
            <br/><br/><br/><br/><br/><br/>


            <div class="botonizquierda"><v-btn id="aceptarduracion" text color="primary" @click="aceptar">{{$i18n.t('aceptar')}} </v-btn></div>
            <div class="botonderecha"><v-btn text color="error" @click="cerrar">{{$i18n.t('cancelar')}}</v-btn></div>
        </v-card>
   </v-dialog>
</div>
</template>

<script>

import {mapState } from 'vuex';

export default {
    name: 'ControlDuracion',   
    data() {
        return {
            visible: false,            
            duracion_texto: '',
            // controlHoraValue: null
            duracion: null,
            // hora: '',
            // minuto:'',

           
        }
    },
    props: {
        label: String,
        value: Number,
        disabled: Boolean,
        // multiploMinutos: {type: Number, required:false, default:0}, //indica que los minutos deben ser multiplo de esa cantidad
        clearable: {type: Boolean, required: false, default: false },
        required: {type: Boolean, required: false, default: true },
        mostrar_icono: {type: Boolean, required: false, default: false }
    },
    computed: {
        ...mapState(['sesion']),
      append_icon(){
        if (this.mostrar_icono) return "schedule";
        else return ""
      },
      duracion_previsualiza(){
          return this.minutos_to_texto(this.duracion);
      }

    },
    watch: {
      value: { immediate: true, handler(newval, oldval) {
            //this.duracion=null;
            if (!newval) {
                this.duracion="";
                // this.minuto="";
                this.duracion_texto = "";
            }
            else {
                // console.log(typeof newval)
              if (typeof newval =="number") {
                  this.duracion = newval;
                //   this.minuto = newval.substring(3,5);
                  this.duracion_texto  = this.minutos_to_texto(newval);//+" min";
              }
            }
         }, 

      },        
    },    
    methods: {
        click() {
            this.visible=true;
            setTimeout(function(){ document.getElementById('aceptarduracion').focus();},50);
        },

        aceptar() {
            const valorAnterior =  this.duracion_texto;
            if (this.duracion) this.duracion_texto = this.minutos_to_texto(this.duracion);// + " minutos";
            else this.duracion_texto = "";
            this.visible=false;
            if (valorAnterior!==this.duracion_texto) {
              this.$emit('input',this.duracion)
              this.$emit('change',this.duracion)
            }

        },
        cerrar(){
            this.visible = false;
        },
        minutos_to_texto(duracion) {
            if (duracion) {
                const horas = Math.floor(duracion/60);
                const minutos = duracion - horas*60;
                let resul="";
                if (horas===0) {
                    resul = minutos + " minutos";
                }
                else if (horas===1) { 
                    if (minutos===0) resul = "1 hora";
                    else resul = "1h "+minutos+"min";
                }
                else if(horas>1) {
                    if (minutos===0) resul = horas+" horas";
                    else resul=horas+ "h "+minutos+"min";
                }
                return resul;
            }
            else return "";
        }
       
       
    },
   
}

</script>

<style>
  .botonizquierda {
      position: absolute;
      left:10px;
      bottom: 10px;
  }
  .botonderecha {
      position: absolute;
      bottom: 10px;
      right:20px;
  }
  .titulo {
      height:70px; 
      background-color:#5050f0; 
      font-size:40px;
      padding-top: 10px;
      text-align: center;
      color: #e0e0e0;
  }
  .texto{
      width: 80px;
 }

</style>
