import Vue from 'vue'
import VueRouter from 'vue-router'
import Agenda from '../views/Agenda.vue'
import Persona from '../views/Persona.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'agenda',
    component: Agenda
  },
  {
    path: '/',
    name: 'persona',
    component: Persona
  },
]

const router = new VueRouter({
  routes
})

export default router
