

<template>
    <div class="marca" :style="estilo_marca" @click="click" > 
    
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {char_to_date, date_diff, date_add, getImagen_tipocita, getImagen_opcion, getImagen_aviso} from '@/library/misFunciones.js';


export default {
    name: 'ControlMarca',   
    computed: {
        ...mapState(['sesion']),
      
        estilo_marca(){    
            if (!this.sesion.agendas_dibujo) return "";
            let top = this.altura_cabecera_dia + date_diff(this.hora_inicio_dia,this.marca.fecha,"minute") * this.pixel_hora/60;
            let height = date_diff(this.marca.fecha,this.marca.fecha_fin,"minute") * this.pixel_hora/60;
            // console.log("height marca:"+height)
            //let bgcolor = "#ffffff";
            //if (this.cita.color_agenda) bgcolor = '#'+this.cita.color_agenda;
            let left,width;
            if (this.sesion.modo_efectivo==="columna") {
                //Miro el orden de la agenda de la cita
                let pos=0;
                while (pos<this.sesion.agendas_dibujo.length && this.marca.agenda!==this.sesion.agendas_dibujo[pos].cod) pos++;
                width=(this.ancho_dia-34)/this.sesion.agendas_dibujo.length;
                //left=32+((document.getElementById('divDia0').clientWidth-34)/this.sesion.agendas_dibujo.length)*pos;
                left=32+((this.ancho_dia-34)/this.sesion.agendas_dibujo.length)*pos;
            }
            else {               
                width=this.ancho_dia-34;
                left = 32;
            }
            //Para que sobresalga por los lados y se vea si tiene una cita encima
            left -= 2;
            width += 4;
            top -= 2;
            height +=4;
         
            // let zorder = "";
            // if (this.cita.zorder) zorder = "z-index:"+this.cita.zorder+";";

            // return "left:"+left+"px; width: "+width+"px; top:"+top+"px; height:"+height+"px; background:"+bgcolor+"; color:#"+this.cita.color_cita+";"+zorder;
            return "left:"+left+"px; width: "+width+"px; top:"+top+"px; height:"+height+"px; background:#"+this.marca.color+";";
        },

    },
    data() {
        return {
        }
    },
    props:{       
        altura_cabecera_dia: Number,
        marca: Object,
        hora_inicio_dia: String,
        pixel_hora: Number,
        ancho_dia: Number,
        // alturaDia: Number,
    },
    methods: {

        click(event){
            this.$emit('click',event,{inicio:this.marca.fecha, fin:this.marca.fecha_fin})
        },

     

    },
    watch: {
    },
    mount() {
    }

}

</script>

<style>

 .marca {
  border-style: dotted;
  border-width: 1px;
  position: absolute;
  overflow: hidden;
  border-color:#000000;
  min-height:12px;
  cursor: pointer;
  font-size: 11px;
  
}
/*.cita>div {
  font-size: 11px;
  float:left; 
  display: inline;
  margin-left: 2px;
} */


</style>
